import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { ApiParams, BankAccount, Identity, Organization } from 'store/api/api.types';
import {
  useGetIdentitiesQuery,
  useLazyGetBankAccountsQuery,
  useLazyGetIdentitiesQuery,
} from 'store/api/platformApi';
import Spinner from 'components/core/Spinner/Spinner';
import Table from 'components/core/Table/Table';
import Text from 'components/core/Text/Text';

import IdentityBankAccounts from './IdentityBankAccounts/IdentityBankAccounts';
import IdentityDropdown from './IdentityDropDown/IdentityDropdown';
import IdentityStatus from './IdentityStatus/IdentityStatus';

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor(row => row.name, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()} </Text>,
    header: 'Name',
    id: 'name',
  }),
  columnHelper.accessor(row => row.country, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()} </Text>,
    header: 'Country',
    id: 'country',
  }),
  columnHelper.accessor(row => row.bankAccounts, {
    cell: info => info.getValue(),
    header: 'Bank Accounts',
    id: 'bankAccounts',
  }),
  columnHelper.accessor(row => row.status, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()}</Text>,
    header: 'Status',
    id: 'status',
  }),
  columnHelper.accessor(row => row.identityDetails, {
    cell: info => info.getValue(),
    header: '',
    id: 'more',
  }),
];

const identityName = (identity: Identity): string => {
  if (identity.businessName && identity.businessName !== ' ') {
    return identity.businessName;
  }
  return `${identity.firstName} ${identity.lastName} `;
};

const GET_BANK_ACCOUNTS_PAGE_SIZE = 100;
const GET_IDENTITIES_PAGE_SIZE = 100;

const IdentitiesTable: FC<{ organizationId: Organization['id']; version: number }> = ({
  organizationId,
  version,
}) => {
  const [bankAccountList, setBankAccountList] = useState<BankAccount[]>([]);
  const [getBankAccounts] = useLazyGetBankAccountsQuery();

  const nextGetBankAccounts = (params: ApiParams) => {
    getBankAccounts({ organizationId, params }).then(results => {
      if (results.data) {
        const resultsData = results.data;
        if (resultsData?.data) {
          setBankAccountList(previous => previous.concat(resultsData?.data));

          if (resultsData?.links?.next) {
            nextGetBankAccounts({
              page: { after: resultsData.links.next, size: GET_BANK_ACCOUNTS_PAGE_SIZE },
            });
          }
        }
      }
    });
  };

  const [identityList, setIdentityList] = useState<Identity[]>([]);
  const [getIdentities, result] = useLazyGetIdentitiesQuery();

  const nextGetIdentities = (params: ApiParams) => {
    getIdentities({ organizationId, params }).then(results => {
      if (results.data) {
        const resultsData = results.data;
        if (resultsData?.data) {
          setIdentityList(previous => previous.concat(resultsData.data));

          if (resultsData?.links?.next) {
            nextGetIdentities({
              page: { after: resultsData.links.next, size: GET_IDENTITIES_PAGE_SIZE },
            });
          }
        }
      }
    });
  };

  useGetIdentitiesQuery({
    organizationId,
    params: { page: { size: 1 } },
  });

  const loadData = () => {
    setIdentityList([]);
    setBankAccountList([]);
    nextGetIdentities({ page: { size: GET_IDENTITIES_PAGE_SIZE } });
    nextGetBankAccounts({ page: { size: GET_BANK_ACCOUNTS_PAGE_SIZE } });
  };

  const reloadData = () => {
    // API needs some time to show the changes
    setTimeout(loadData, 2000);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  const identities = useMemo<any[]>(() => {
    if (!identityList || !bankAccountList) {
      return [];
    }

    const rows: any[] = [];

    [...identityList]
      .sort((a, b) => {
        return identityName(a).toLowerCase() < identityName(b).toLowerCase() ? -1 : 1;
      })
      .forEach(async identity => {
        rows.push({
          bankAccounts: (
            <IdentityBankAccounts
              bankAccounts={bankAccountList}
              identity={identity}
              organizationId={organizationId}
              parentReload={reloadData}
            />
          ),
          country: identity.nationality,
          identityDetails: (
            <IdentityDropdown
              identity={identity}
              organizationId={organizationId}
              parentReload={reloadData}
            />
          ),
          name: identityName(identity),
          status: <IdentityStatus identity={identity} />,
        });
      });

    return rows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityList, bankAccountList, organizationId]);

  if ((result.isUninitialized || result.isLoading) && identities.length === 0) {
    return <Spinner display='centered' />;
  }

  if (identities?.length === 0) {
    return (
      <Text align='center' marginTop={5} variant='bodyCopySmall'>
        No Senders / Recipients Created
      </Text>
    );
  }

  return (
    <Table
      options={{
        columns,
        data: identities,
        getCoreRowModel: getCoreRowModel(),
      }}
    />
  );
};

export default IdentitiesTable;
