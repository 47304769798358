import React, { ChangeEvent, forwardRef, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import FormErrorMessage from 'components/core/Form/FormErrorMessage/FormErrorMessage';
import Text from 'components/core/Text/Text';
import generateUid from 'utils/generateUid';

import { InputFieldProps } from './InputField.types';
import styles from './InputField.module.scss';

function InputField<T extends InputFieldProps>(
  {
    Icon,
    children,
    className,
    dataTestId = '',
    error,
    id,
    inputMode,
    isDisabled = false,
    isHidden = false,
    isMultiline = false,
    isRequired = false,
    label,
    longLabel,
    name,
    onBlur,
    onChange: onChangeProp,
    onKeyDown,
    placeholder,
    size = 'normal',
    supportingText,
    type,
    value,
    ...rest
  }: T,
  ref,
) {
  const [hasValue, setHasValue] = useState(!!value);

  useEffect(() => {
    setHasValue(!!value);
  }, [value]);

  const fieldId = useMemo(() => {
    return id || generateUid();
  }, [id]);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setHasValue(!!event.target.value);
    if (onChangeProp) {
      onChangeProp(event);
    }
  };

  const sharedProps = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'data-testid': dataTestId,
    disabled: isDisabled,
    id: fieldId,
    inputMode,
    name,
    onBlur,
    onChange,
    onKeyDown,
    placeholder,
    ref,
    required: isRequired,
    type,
    value,
  };

  return (
    <>
      {!isHidden && longLabel && <Text className={styles.longLabel}>{longLabel}</Text>}
      <div
        className={cx(
          styles.root,
          isDisabled && styles.isDisabled,
          isHidden && styles.isHidden,
          className,
        )}
      >
        <div className={cx(styles.fieldWrapper, styles[`size--${size}`])}>
          {Icon}
          {isMultiline ? (
            <textarea
              {...sharedProps}
              className={cx(
                styles.textarea,
                styles.input,
                !label && styles.placeholderAlwaysVisible,
                hasValue && styles.hasValue,
                error && styles.hasError,
              )}
            />
          ) : (
            <input
              {...sharedProps}
              {...rest}
              className={cx(
                styles.input,
                !label && styles.placeholderAlwaysVisible,
                hasValue && styles.hasValue,
                error && styles.hasError,
              )}
            />
          )}
          <fieldset className={styles.fieldset}>
            <legend className={styles.legend}>{label && <span>{label}</span>}</legend>
          </fieldset>
          {label && (
            <label className={styles.label} htmlFor={fieldId}>
              {label}
            </label>
          )}
        </div>
        {(supportingText || error) && (
          <div className={styles.supportingTextWrapper}>
            {error ? (
              <FormErrorMessage
                className={styles.errorMessage}
                dataTestId={`${name}-error`}
                message={error}
              />
            ) : (
              supportingText
            )}
          </div>
        )}

        {children}
      </div>
    </>
  );
}

export default forwardRef(InputField);
