import { components } from 'react-select';
import React, { FC } from 'react';
import cx from 'classnames';

import { chevronRight } from 'components/core/Svg/icons';
import Svg from 'components/core/Svg/Svg';

import { SelectFieldDropdownIndicatorProps } from './SelectFieldDropdownIndicator.types';
import styles from './SelectFieldDropdownIndicator.module.scss';

const SelectFieldDropdownIndicator: FC<SelectFieldDropdownIndicatorProps> = ({
  hasError,
  ...props
}) => {
  return (
    <components.DropdownIndicator {...props}>
      <Svg
        className={cx(styles.chevron, hasError && styles.hasError)}
        img={chevronRight}
        size={[1.06, 1.6]}
      />
    </components.DropdownIndicator>
  );
};

export default SelectFieldDropdownIndicator;
