import React, { FC } from 'react';
import cx from 'classnames';

import { TransactionType } from 'store/api/api.types';
import { arrowLeftDown, arrowRightUp, minus, plus } from 'components/core/Svg/icons';
import Box from 'components/core/Box/Box';
import Svg from 'components/core/Svg/Svg';

import { TransactionTypeIconProps } from './TransactionTypeIcon.types';
import styles from './TransactionTypeIcon.module.scss';

const TransactionTypeIcon: FC<TransactionTypeIconProps> = ({
  className,
  transactionType,
  ...props
}) => {
  const directionIconPerTransactionType = {
    [TransactionType.Onramp]: arrowLeftDown,
    [TransactionType.Offramp]: arrowRightUp,
    [TransactionType.Fx]: arrowRightUp,
    [TransactionType.Deposit]: plus,
    [TransactionType.Withdrawal]: minus,
  };
  return (
    <Box className={cx(styles.root, className)} {...props}>
      <Svg
        className={styles.directionIcon}
        img={directionIconPerTransactionType[transactionType]}
        size={1.3}
      />
    </Box>
  );
};

export default TransactionTypeIcon;
