import React, { FC } from 'react';
import cx from 'classnames';

import Box from 'components/core/Box/Box';

import TextProps from './Text.types';
import styles from './Text.module.scss';

const Text: FC<TextProps> = ({
  align,
  children,
  className,
  overflow,
  variant = 'bodyCopyStandard',
  ...props
}) => (
  <Box
    className={cx(
      styles.root,
      styles[`variant--${variant}`],
      align && styles[`align--${align}`],
      overflow && styles[`overflow--${overflow}`],
      className,
    )}
    {...props}
  >
    {children}
  </Box>
);

export default Text;
