import { useOutletContext } from 'react-router-dom';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { AssetType, AssetTypeUri } from 'constants/assetTypes.types';
import { AssetTypeBalanceValidity } from 'components/core/Form/AssetTypeField/AssetTypeField.types';
import { SendMoneyOutletContext } from 'components/views/app/organization/SendMoney/SendMoney.types';
import { formatAmount, isFiat } from 'utils/format';
import {
  sourceAmountValidationFunction,
  targetAmountValidationFunction,
} from 'components/core/Form/AssetTypeField/AssetTypeField.utils';
import { useCreateTransactionContext } from 'context/CreateTransactionContext';
import AssetTypeField from 'components/core/Form/AssetTypeField/AssetTypeField';
import Button from 'components/core/Button/Button';
import Icon from 'components/core/Icon/Icon';
import Text from 'components/core/Text/Text';
import localStorageService from 'services/localStorageService';

import {
  ASSETS_SELECTOR_SOURCE_ASSET_ID_LOCAL_STORAGE_KEY,
  ASSETS_SELECTOR_TARGET_ASSET_ID_LOCAL_STORAGE_KEY,
} from './AssetsSelector.constants';
import { AssetsSelectorProps } from './AssetsSelector.types';
import {
  hasTradeablePair as getHasTradeablePair,
  hasAnyTradeablePairsEnabled,
  sortAssetUrisByTicker,
} from './AssetsSelector.utils';
import styles from './AssetsSelector.module.scss';

const AssetsSelector: FC<AssetsSelectorProps> = ({
  setIsSourceAmountValid,
  setIsTargetAmountValid,
}) => {
  const { assetTypes, tradingPairs } = useOutletContext<SendMoneyOutletContext>();
  const {
    setAmount,
    setAmountType,
    setRecipient,
    setSender,
    setSourceAmount,
    setSourceAssetType,
    setTargetAmount,
    setTargetAssetType,
    sourceAmount,
    sourceAssetType,
    targetAmount,
    targetAssetType,
    isQuoteFormDisabled,
  } = useCreateTransactionContext();

  const [availableSourceAssetTypes, setAvailableSourceAssetTypes] = useState<AssetType[]>([]);
  const [availableTargetAssetTypes, setAvailableTargetAssetTypes] = useState<AssetType[]>([]);

  const hasTradeablePair = useCallback(
    (source, target) => getHasTradeablePair(tradingPairs, source, target),
    [tradingPairs],
  );
  const hasAnyTradeablePairs = useCallback(
    source => hasAnyTradeablePairsEnabled(tradingPairs, source),
    [tradingPairs],
  );

  useEffect(() => {
    const sortedTradingPairs = (Object.keys(tradingPairs) as AssetTypeUri[]).sort(
      sortAssetUrisByTicker,
    );

    const sourcePairs = sortedTradingPairs.reduce((acc, assetUri) => {
      const assetType = assetTypes.find(asset => asset.id === assetUri);
      if (assetType) {
        acc.push({
          ...assetType,
          isDisabled: !hasAnyTradeablePairs(assetType.id),
        });
      }
      return acc;
    }, [] as AssetType[]);

    setAvailableSourceAssetTypes(sourcePairs);

    if (sourcePairs.length) {
      const storedSourceAssetTypeId = localStorageService.get(
        ASSETS_SELECTOR_SOURCE_ASSET_ID_LOCAL_STORAGE_KEY,
      ) as AssetType['id'];
      const source =
        sourcePairs.find(asset => asset.id === storedSourceAssetTypeId && !asset.isDisabled) ||
        sourcePairs.find(asset => !asset.isDisabled);
      if (source) {
        setSourceAssetType(source);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAssetTypes = useCallback(
    (source?: AssetType, target?: AssetType) => {
      setAmount('');
      setAmountType('none');
      setSourceAssetType(source);
      localStorageService.set(ASSETS_SELECTOR_SOURCE_ASSET_ID_LOCAL_STORAGE_KEY, source?.id);
      setTargetAssetType(target);
      localStorageService.set(ASSETS_SELECTOR_TARGET_ASSET_ID_LOCAL_STORAGE_KEY, target?.id);
      setSourceAmount('');
      setTargetAmount('');
      if (source !== sourceAssetType) {
        setSender(undefined);
      }
      if (target !== targetAssetType) {
        setRecipient(undefined);
      }
    },
    [
      setAmount,
      setAmountType,
      setRecipient,
      setSender,
      setSourceAmount,
      setSourceAssetType,
      setTargetAmount,
      setTargetAssetType,
      sourceAssetType,
      targetAssetType,
    ],
  );

  const swapAssetTypes = useCallback(() => {
    setAssetTypes(targetAssetType!, sourceAssetType!);
  }, [setAssetTypes, sourceAssetType, targetAssetType]);

  const handleSourceAssetTypeChange = useCallback(
    (source: AssetType) => {
      let target = targetAssetType;
      const targetPairs = tradingPairs[source.id];
      const targetAssetTypes = targetPairs.reduce((acc, targetPair) => {
        const targetType = assetTypes.find(asset => asset.id === targetPair.uri);
        if (targetType) {
          acc.push({
            ...targetType,
            isDisabled: !targetPair.enabled,
          });
        }
        return acc;
      }, [] as AssetType[]);

      if (
        !targetAssetType ||
        !targetAssetTypes.find(asset => asset.id === targetAssetType.id && !asset.isDisabled)
      ) {
        target =
          targetAssetTypes.find(
            type =>
              // Run it only if the target asset type is not set yet - on the component mount.
              !targetAssetType &&
              type.id ===
                localStorageService.get(ASSETS_SELECTOR_TARGET_ASSET_ID_LOCAL_STORAGE_KEY) &&
              !type.isDisabled,
            // Enabled source asset types always have at least one enabled target asset type.
          ) || targetAssetTypes.find(type => !type.isDisabled);
      }

      setAvailableTargetAssetTypes(targetAssetTypes);
      setAssetTypes(source, target);
    },
    [assetTypes, setAssetTypes, targetAssetType, tradingPairs],
  );

  useEffect(() => {
    if (sourceAssetType) {
      handleSourceAssetTypeChange(sourceAssetType);
    }
  }, [sourceAssetType, handleSourceAssetTypeChange]);

  const onSourceAssetTypeChange = useCallback(
    (source: AssetType) => {
      setSourceAssetType(source);
    },
    [setSourceAssetType],
  );

  const onTargetAssetTypeChange = useCallback(
    target => {
      setAssetTypes(sourceAssetType, target);
    },
    [setAssetTypes, sourceAssetType],
  );

  const updateSourceAmount = useCallback(
    amount => {
      setAmountType('source');
      setAmount(amount);
      setSourceAmount(amount);
      setTargetAmount('');
    },
    [setAmount, setAmountType, setSourceAmount, setTargetAmount],
  );

  const updateTargetAmount = useCallback(
    amount => {
      setAmountType('target');
      setAmount(amount);
      setSourceAmount('');
      setTargetAmount(amount);
    },
    [setAmount, setAmountType, setSourceAmount, setTargetAmount],
  );

  const updateSourceValidity = useCallback(
    status => {
      setIsSourceAmountValid(status === AssetTypeBalanceValidity.Valid);
    },
    [setIsSourceAmountValid],
  );

  const updateTargetValidity = useCallback(
    status => {
      setIsTargetAmountValid(status === AssetTypeBalanceValidity.Valid);
    },
    [setIsTargetAmountValid],
  );

  const canSwap = useMemo(() => {
    return (
      sourceAssetType && targetAssetType && hasTradeablePair(targetAssetType.id, sourceAssetType.id)
    );
  }, [hasTradeablePair, sourceAssetType, targetAssetType]);

  return (
    <div className={styles.root}>
      <AssetTypeField
        amountFieldValue={sourceAmount}
        amountLabel='Send'
        amountValidatorFunction={sourceAmountValidationFunction}
        assetType={sourceAssetType}
        assetTypePair={targetAssetType}
        assetTypes={availableSourceAssetTypes}
        className={styles.topMost}
        isDisabled={isQuoteFormDisabled}
        onAmountChange={updateSourceAmount}
        onAssetTypeChange={onSourceAssetTypeChange}
        onValidationChange={updateSourceValidity}
      />
      {!isFiat(sourceAssetType) && sourceAssetType?.balance && (
        <Text align='right' className={styles.balance} variant='bodyCopySmall'>
          Balance: {formatAmount(sourceAssetType, 3)}
        </Text>
      )}
      <Button
        align='center'
        className={styles.swap}
        Icon={<Icon iconName='swap' size={4} />}
        isDisabled={!canSwap}
        onClick={swapAssetTypes}
        variant='icon'
      />
      <AssetTypeField
        amountFieldValue={targetAmount}
        amountLabel='Receive'
        amountValidatorFunction={targetAmountValidationFunction}
        assetType={targetAssetType}
        assetTypePair={sourceAssetType}
        assetTypes={availableTargetAssetTypes}
        isDisabled={isQuoteFormDisabled}
        onAmountChange={updateTargetAmount}
        onAssetTypeChange={onTargetAssetTypeChange}
        onValidationChange={updateTargetValidity}
      />
    </div>
  );
};

export default AssetsSelector;
