import { ApiTransactionSummaryAttributes, TransactionType } from 'store/api/api.types';
import { formatAmount, getUriId, getUriIdAndChainId } from 'utils/format';

import { ParsedTransactionItemAttributes } from './TransactionItem.types';

export const getParsedTransactionItemAttributes = (
  attributes: ApiTransactionSummaryAttributes,
): ParsedTransactionItemAttributes => {
  const { createdAt, destination, source, status, transactionType } = attributes;

  // Transaction time
  const date = new Date(createdAt);
  const creationTime = {
    day: date.getDate(),
    month: date.toLocaleString('en-US', { month: 'short' }),
    year: date.getFullYear(),
  };

  const targetAsset = destination.assetType && getUriIdAndChainId(destination.assetType);

  const targetAmount = formatAmount({
    amount: destination.amount,
    assetType: destination.assetType,
  });

  const sourceAsset = source.assetType && getUriIdAndChainId(source.assetType);

  const sourceAmount =
    source.amount && formatAmount({ amount: source.amount, assetType: source.assetType });

  const CONDUIT_ACCOUNT_NAME = 'My Conduit Account';

  // Recipient
  let sender;
  let recipient;
  switch (transactionType) {
    case TransactionType.Onramp:
      recipient = CONDUIT_ACCOUNT_NAME;
      sender =
        source.bankAccountHolder?.businessName ||
        `${source.bankAccountHolder?.firstName} ${source.bankAccountHolder?.lastName}`;
      break;
    case TransactionType.Offramp:
    case TransactionType.Fx:
      recipient = destination.bank?.nameOnBankAccount;
      sender = CONDUIT_ACCOUNT_NAME;
      break;
    case TransactionType.Deposit:
      recipient = CONDUIT_ACCOUNT_NAME;
      sender = getUriIdAndChainId(source.id);
      break;
    case TransactionType.Withdrawal:
      recipient = getUriId(destination.id);
      sender = CONDUIT_ACCOUNT_NAME;
      break;
    default:
      break;
  }

  return {
    creationTime,
    recipient,
    sender,
    sourceAmount,
    sourceAsset,
    status,
    targetAmount,
    targetAsset,
    transactionType,
  };
};
