import { generatePath, useNavigate } from 'react-router-dom';
import React, { ReactElement, useEffect } from 'react';

import { APP_ROUTES, ORGANIZATIONS_ROUTES } from 'router/constants';
import { useGetOrganizationsQuery } from 'store/api/rootApi';
import Button from 'components/core/Button/Button';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';

import styles from './OrganizationsView.module.scss';

const OrganizationsView = (): ReactElement => {
  const navigate = useNavigate();
  const { data: organizations, isFetching } = useGetOrganizationsQuery();

  useEffect(() => {
    if (!isFetching && organizations) {
      if (organizations.length === 0) {
        navigate(ORGANIZATIONS_ROUTES.CREATE_ORGANIZATION.absolute);
      }
      if (organizations.length === 1) {
        const { id } = organizations[0];
        navigate(
          generatePath(ORGANIZATIONS_ROUTES.ORGANIZATION.absolute, {
            organizationId: id,
          }),
        );
      }
    }
  }, [isFetching, navigate, organizations]);

  return (
    <div className={styles.root} data-testid='OrganizationsView'>
      <Text align='center' className={styles.header} variant='legacyTitle'>
        Select Organization
      </Text>
      <div className={styles.buttonsContainer}>
        {organizations &&
          organizations.map(({ id, name }) => (
            <Button
              key={id}
              className={styles.organizationButton}
              dataTestId={`Button-organization-${id}`}
              to={`${APP_ROUTES.ORGANIZATIONS.absolute}/${id}`}
              variant='secondary'
            >
              {name}
            </Button>
          ))}
        {isFetching && <Spinner display='centered' />}
      </div>
    </div>
  );
};

export default OrganizationsView;
