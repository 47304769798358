import { TimeRange } from './isCurrentTimeInRange.types';

export const isCurrentTimeInRange = (config: TimeRange): boolean => {
  const { start, stop, timezone } = config;

  const now = new Date();
  const timezoneOffset = now.toLocaleString('en', { timeZone: timezone });
  const localTime = new Date(timezoneOffset);
  const currentHours = localTime.getHours();
  const currentMinutes = localTime.getMinutes();

  const startTimeInMinutes = start.hour * 60 + start.minute;
  const stopTimeInMinutes = stop.hour * 60 + stop.minute;
  const currentTimeInMinutes = currentHours * 60 + currentMinutes;

  return currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes <= stopTimeInMinutes;
};
