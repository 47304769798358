import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Links } from '../store/api/api.types';

const usePaginationLinks = (
  links?: Links,
  pageSize?: number,
): {
  handleOnNextPage?: () => void;
  handleOnPreviousPage?: () => void;
} => {
  const navigate = useNavigate();
  const nextPageCursor = useMemo(() => links?.next, [links]);
  const prevPageCursor = useMemo(() => links?.prev, [links]);

  const pageSizeQueryParam = pageSize ? `&pageSize=${pageSize}` : '';

  const handleOnNextPage = () => {
    navigate(`${window.location.pathname}?pageAfter=${nextPageCursor}${pageSizeQueryParam}`);
  };

  const handleOnPreviousPage = () => {
    navigate(`${window.location.pathname}?pageBefore=${prevPageCursor}${pageSizeQueryParam}`);
  };

  return {
    handleOnNextPage: nextPageCursor ? handleOnNextPage : undefined,
    handleOnPreviousPage: prevPageCursor ? handleOnPreviousPage : undefined,
  };
};

export default usePaginationLinks;
