import { CSSTransition } from 'react-transition-group';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { DEFAULT_TRANSITION_TIME } from 'constants/constants';
import { arrowRoundedUp } from 'components/core/Svg/icons';
import Svg from 'components/core/Svg/Svg';

import { BackToTopButtonProps } from './BackToTopButton.types';
import styles from './BackToTopButton.module.scss';

const BackToTopButton: FC<BackToTopButtonProps> = ({
  className,
  dataTestId,
  offsetFromTop = 20,
  isVisible,
}) => {
  const nodeRef = useRef(null);
  const [isVisibleState, setIsVisibleState] = useState(isVisible !== undefined ? isVisible : false);

  const toggleVisibility = useCallback(() => {
    setIsVisibleState(window.scrollY > offsetFromTop);
  }, [offsetFromTop]);

  const handleScrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  useEffect(() => {
    if (isVisible !== undefined) {
      return;
    }
    window.addEventListener('scroll', toggleVisibility);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, [isVisible, toggleVisibility]);

  return (
    <CSSTransition
      classNames={{
        enter: styles['root--enter'],
        enterActive: styles['root--enterActive'],
        exit: styles['root--exit'],
        exitActive: styles['root--exitActive'],
      }}
      in={isVisible !== undefined ? isVisible : isVisibleState}
      nodeRef={nodeRef}
      timeout={DEFAULT_TRANSITION_TIME}
      unmountOnExit
    >
      <div
        ref={nodeRef}
        className={cx(styles.root, className)}
        data-testid={dataTestId}
        onClick={handleScrollToTop}
      >
        <Svg className={styles.icon} img={arrowRoundedUp} size={1.6} />
        Back To Top
      </div>
    </CSSTransition>
  );
};

export default BackToTopButton;
