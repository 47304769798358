import React, { FC, useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';

import { TransactionStatus } from 'store/api/api.types';
import { useGetTransactionSummaryQuery } from 'store/api/platformApi';
import Spinner from 'components/core/Spinner/Spinner';

import { TransactionDetailProps } from './TransactionDetail.types';
import OffRampTransaction from './OffRampTransaction/OffRampTransaction';
import OnRampTransactionWithInstructions from './OnRampTransaction/OnRampTransaction';
import RejectedTransaction from './RejectedTransaction/RejectedTransaction';

let refetchQueryTimeout: NodeJS.Timeout | number | undefined;
let isMounted: boolean;
const QUERY_REFETCH_INTERVAL = 3000;

const TransactionDetail: FC<TransactionDetailProps> = ({
  organizationId,
  showTitle,
  transactionId,
}) => {
  const {
    data: transactionSummaries,
    isLoading: isTransactionSummaryLoading,
    refetch: refetchTransactionSummary,
  } = useGetTransactionSummaryQuery({ organizationId, transactionId });

  const refetchQueryTimeoutRef = useRef(refetchQueryTimeout);
  const isMountedRef = useRef(isMounted);

  const transactionAttributes = useMemo(() => {
    return transactionSummaries?.attributes;
  }, [transactionSummaries]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      clearTimeout(refetchQueryTimeoutRef.current);
    };
  });

  const scheduleRefetchQuery = debounce(() => {
    if (!isMountedRef.current) {
      return;
    }
    clearTimeout(refetchQueryTimeoutRef.current);
    refetchQueryTimeoutRef.current = setTimeout(() => {
      refetchTransactionSummary();
    }, QUERY_REFETCH_INTERVAL);
  }, QUERY_REFETCH_INTERVAL);

  if (isTransactionSummaryLoading || !transactionAttributes) {
    return <Spinner display='centered' />;
  }

  if (transactionAttributes?.status === TransactionStatus.RejectedCompliance) {
    return <RejectedTransaction showTitle={showTitle} />;
  }

  if (transactionAttributes?.transactionType === 'offramp') {
    return (
      <OffRampTransaction showTitle={showTitle} transactionAttributes={transactionAttributes} />
    );
  }

  if (
    !transactionAttributes.fx?.payInInstructions &&
    !transactionAttributes.ramp?.depositInstructions
  ) {
    scheduleRefetchQuery();
  }

  return (
    <OnRampTransactionWithInstructions
      showTitle={showTitle}
      transactionAttributes={transactionAttributes}
    />
  );
};

export default TransactionDetail;
