import React, { FC, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { BankAccount, Identity, Organization } from 'store/api/api.types';
import { useCurrentUser } from 'context/CurrentUserContext';
import Badge from 'components/core/Badge/Badge';
import Icon from 'components/core/Icon/Icon';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

import IdentityBankAccount from './IdentityBankAccount/IdentityBankAccount';
import styles from './IdentityBankAccounts.module.scss';

import ModalBankAccountCreate from '../../ModalBankAccountCreate/ModalBankAccountCreate';

const IdentityBanks: FC<{
  bankAccounts: BankAccount[];
  identity: Identity;
  organizationId: Organization['id'];
  parentReload: () => void;
}> = ({ bankAccounts, identity, organizationId, parentReload }) => {
  const { isUserAllowedTo } = useCurrentUser();

  const canCreateBankAccount = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.CreateBankAccount),
    [organizationId, isUserAllowedTo],
  );

  const banks = useMemo<BankAccount[]>(
    () =>
      bankAccounts
        .filter(bankAccount =>
          bankAccount.relationships.identities.data.find(
            bankIdentity => bankIdentity.id === identity.id,
          ),
        )
        .sort((a, b) => {
          return `${a.attributes.country}-${a.attributes.bankName}-${a.attributes.accountNumber.slice(-4)}` <
            `${b.attributes.country}-${b.attributes.bankName}-${b.attributes.accountNumber.slice(-4)}`
            ? -1
            : 1;
        }),
    [bankAccounts, identity],
  );

  const identityName = useMemo(() => {
    if (identity.businessName && identity.businessName !== ' ') {
      return identity.businessName;
    }
    return `${identity.firstName} ${identity.lastName} `;
  }, [identity]);

  return (
    <Text className={styles.banks} variant='bodyCopySmall'>
      {banks.map(bank => (
        <IdentityBankAccount
          key={bank.id}
          bankAccount={bank}
          organizationId={organizationId}
          parentReload={parentReload}
        />
      ))}

      {canCreateBankAccount && (
        <Badge
          className={styles.addBank}
          color='yellow'
          onClick={() =>
            modalService.openModal(
              {
                title: `Add a Bank for ${identityName}`,
              },
              <ModalBankAccountCreate
                identity={identity}
                organizationId={organizationId}
                parentReload={parentReload}
              />,
            )
          }
        >
          <Icon iconName='circlePlus' size={1.8} />
          <span>Add</span>
        </Badge>
      )}
    </Text>
  );
};

export default IdentityBanks;
