/* eslint-disable no-console */

import { generatePath, useNavigate, useParams } from 'react-router-dom';
import React, { ReactElement, useEffect, useState } from 'react';

import { ORGANIZATIONS_ROUTES } from 'router/constants';
import { isGenericErrorType } from 'store/api/api.utils';
import { useAcceptInviteMutation } from 'store/api/rootApi';
import Button from 'components/core/Button/Button';
import Noticebox from 'components/core/Noticebox/Noticebox';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';

import styles from './InviteAcceptView.module.scss';

const InviteAcceptView = (): ReactElement => {
  const { inviteHash } = useParams() as { inviteHash: string };
  const [acceptInvite, { isLoading }] = useAcceptInviteMutation();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const acceptedInvite = await acceptInvite({ inviteHash });
      if ('error' in acceptedInvite) {
        if (isGenericErrorType(acceptedInvite.error)) {
          setError(JSON.stringify(error, null, 4));
        } else {
          setError((acceptedInvite.error as any).data.errors?.[0].title);
        }
      } else {
        const { organizationId } = acceptedInvite.data as any;
        const orgPath = generatePath(ORGANIZATIONS_ROUTES.ORGANIZATION.absolute, {
          organizationId,
        });
        navigate(orgPath);
      }
    })();
  }, [acceptInvite, error, inviteHash, navigate]);

  const handleReturnHome = () => {
    navigate('/');
  };

  if (isLoading) {
    return <Spinner display='centered' />;
  }
  return (
    <div className={styles.root}>
      <Noticebox variant='warning'>
        <Text>{error}</Text>
      </Noticebox>
      <div className={styles.ctaContainer}>
        <Button label='Return Home' onClick={handleReturnHome} />
      </div>
    </div>
  );
};

export default InviteAcceptView;
