import { CSSTransition } from 'react-transition-group';
import React, { FC, useRef } from 'react';
import cx from 'classnames';

import { DEFAULT_TRANSITION_TIME } from 'constants/constants';

import ModalBox from './ModalBox/ModalBox';
import ModalProps from './Modal.types';
import styles from './Modal.module.scss';

const Modal: FC<ModalProps> = ({
  children,
  dataTestId,
  disableScrollOnLargeScreen = false,
  isOpen = false,
  onClosed,
  title,
  type = 'default',
}) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      classNames={{
        enter: styles[`${type}--enter`],
        enterActive: styles[`${type}--enterActive`],
        exit: styles[`${type}--exit`],
        exitActive: styles[`${type}--exitActive`],
      }}
      in={isOpen}
      nodeRef={nodeRef}
      timeout={DEFAULT_TRANSITION_TIME}
      unmountOnExit
    >
      <ModalBox
        className={cx(styles[`type--${type}`], styles.root)}
        dataTestId={dataTestId}
        disableScrollOnLargeScreen={disableScrollOnLargeScreen}
        nodeRef={nodeRef}
        onClosed={onClosed}
        title={title}
        type={type}
      >
        {children}
      </ModalBox>
    </CSSTransition>
  );
};

export default Modal;
