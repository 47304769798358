import React, { ReactElement, useCallback, useState } from 'react';

import { CreateTransactionContextProvider } from 'context/CreateTransactionContextProvider';
import { QuoteForm, QuotePreview } from 'components/dedicated/organization/send-money';

import styles from './QuoteView.module.scss';

const QuoteView = (): ReactElement => {
  const [isQuotePreview, setIsQuotePreview] = useState(false);

  const onContinue = useCallback(() => {
    setIsQuotePreview(true);
  }, []);

  const onCancel = useCallback(() => {
    setIsQuotePreview(false);
  }, []);

  return (
    <CreateTransactionContextProvider>
      <div className={!isQuotePreview ? '' : styles.hide}>
        <QuoteForm onContinue={onContinue} />
      </div>
      <div className={isQuotePreview ? '' : styles.hide}>
        <QuotePreview onCancel={onCancel} />
      </div>
    </CreateTransactionContextProvider>
  );
};

export default QuoteView;
