import { createApi } from '@reduxjs/toolkit/query/react';

import {
  Account,
  ApiParams,
  ApiTransactionSummaries,
  ApiTransactionSummary,
  BankAccount,
  BankAccountRequest,
  BankAccounts,
  Identities,
  Identity,
  IdentityRequest,
  KeyData,
  Organization,
  Quote,
  QuoteDetail,
  QuoteRequest,
  RequestPageParams,
  TradingPairs,
  Transaction,
  TransactionDetail,
  TransactionList,
  TransactionRequest,
  Wallets,
} from 'store/api/api.types';
import { baseQuery } from 'store/api/api.config';
import getQueryStringFromParams from 'utils/getQueryStringFromParams';

export const platformApi = createApi({
  baseQuery,
  endpoints: builder => ({
    createBankAccount: builder.mutation<BankAccount, BankAccountRequest>({
      invalidatesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'BankAccounts' },
      ],
      query: ({ data, organizationId }) => ({
        body: data,
        method: 'POST',
        url: `organizations/${organizationId}/platform/bank-accounts`,
      }),
    }),
    createIdentity: builder.mutation<Identity, IdentityRequest>({
      invalidatesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Identities' },
      ],
      query: ({ data, organizationId }) => ({
        body: data,
        method: 'POST',
        url: `organizations/${organizationId}/platform/identities`,
      }),
    }),
    createKey: builder.mutation<KeyData, { organizationId: string }>({
      invalidatesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Keys' },
      ],
      query: ({ organizationId }) => ({
        method: 'POST',
        url: `organizations/${organizationId}/platform/keys`,
      }),
    }),
    createQuote: builder.mutation<Quote, QuoteRequest>({
      invalidatesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Quotes' },
      ],
      query: ({
        organizationId,
        sourceAmount,
        sourceAssetType,
        targetAmount,
        targetAssetType,
      }) => ({
        body: {
          source: { amount: sourceAmount, assetType: sourceAssetType },
          target: { amount: targetAmount, assetType: targetAssetType },
        },
        method: 'POST',
        url: `organizations/${organizationId}/platform/quotes`,
      }),
    }),
    createTransaction: builder.mutation<Transaction, TransactionRequest>({
      invalidatesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Transactions' },
      ],
      query: ({
        amount,
        bank,
        assetType,
        destination,
        organizationId,
        quote,
        source,
        reference,
        file,
        purpose,
      }) => {
        const formData = new FormData();
        formData.append('amount', amount);
        formData.append('source', source);
        formData.append('assetType', assetType);
        formData.append('destination', destination);
        if (bank) {
          formData.append('bank', bank);
        }
        if (quote) {
          formData.append('quote', quote);
        }
        if (purpose) {
          formData.append('purpose', purpose);
        }
        if (reference) {
          formData.append('reference', reference);
        }
        if (file) {
          formData.append('file', file);
        }
        return {
          body: formData,
          method: 'POST',
          url: `organizations/${organizationId}/platform/transactions`,
        };
      },
    }),
    deleteBankAccount: builder.mutation<
      any,
      { bankAccountId: BankAccount['id']; organizationId: Organization['id'] }
    >({
      invalidatesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'BankAccounts' },
      ],
      query: ({ organizationId, bankAccountId }) => ({
        method: 'DELETE',
        url: `organizations/${organizationId}/platform/bank-accounts/${bankAccountId}`,
      }),
    }),
    deleteIdentity: builder.mutation<
      any,
      { identityId: Identity['id']; organizationId: Organization['id'] }
    >({
      invalidatesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Identities' },
      ],
      query: ({ organizationId, identityId }) => ({
        method: 'DELETE',
        url: `organizations/${organizationId}/platform/identities/${identityId}`,
      }),
    }),
    getBankAccounts: builder.query<
      BankAccounts,
      { organizationId: Organization['id']; params?: ApiParams }
    >({
      providesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'BankAccounts' },
      ],
      query: ({ organizationId, params }) => {
        const queryStringParams = getQueryStringFromParams(params);
        return `organizations/${organizationId}/platform/bank-accounts${queryStringParams}`;
      },
    }),
    getEnvironmentAccount: builder.query<Account, { organizationId: Organization['id'] }>({
      providesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'EnvironmentAccount' },
      ],
      query: ({ organizationId }) => `organizations/${organizationId}/platform/environment-account`,
    }),
    getIdentities: builder.query<
      Identities,
      { organizationId: Organization['id']; params?: ApiParams }
    >({
      providesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Identities' },
      ],
      query: ({ organizationId, params }) => {
        const queryStringParams = getQueryStringFromParams(params);
        return `organizations/${organizationId}/platform/identities${queryStringParams}`;
      },
    }),
    getKeys: builder.query<KeyData[], { organizationId: Organization['id'] }>({
      providesTags: (_result, _error, { organizationId }) => [{ id: organizationId, type: `Keys` }],
      query: ({ organizationId }) => `organizations/${organizationId}/platform/keys`,
    }),
    getQuote: builder.query<
      QuoteDetail,
      { organizationId: Organization['id']; quoteId: Quote['id'] }
    >({
      providesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Quotes' },
      ],
      query: ({ organizationId, quoteId }) =>
        `organizations/${organizationId}/platform/quotes/${quoteId}`,
    }),
    getTradingPairs: builder.query<TradingPairs, { organizationId: Organization['id'] }>({
      providesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'TradingPairs' },
      ],
      query: ({ organizationId }) => `organizations/${organizationId}/platform/trading-pairs`,
    }),
    getTransaction: builder.query<
      TransactionDetail,
      { organizationId: Organization['id']; transactionId: Transaction['id'] }
    >({
      providesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Transactions' },
      ],
      query: ({ organizationId, transactionId }) =>
        `organizations/${organizationId}/platform/transactions/${transactionId}`,
    }),
    getTransactionSummaries: builder.query<
      ApiTransactionSummaries,
      { organizationId: Organization['id']; params: ApiParams }
    >({
      providesTags: (_result, _error, { organizationId, params: { filter, page, sort } }) => [
        { filter, id: organizationId, page, sort, type: 'TransactionSummaries' },
      ],
      query: ({ organizationId, params: { page, filter, sort } }) => {
        const queryStringParams = getQueryStringFromParams({
          filter,
          page,
          sort,
        });
        return `organizations/${organizationId}/platform/transactions/summaries${queryStringParams}`;
      },
    }),
    getTransactionSummary: builder.query<
      ApiTransactionSummary,
      { organizationId: Organization['id']; transactionId: Transaction['id'] }
    >({
      providesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'TransactionSummaries' },
      ],
      query: ({ organizationId, transactionId }) =>
        `organizations/${organizationId}/platform/transactions/summaries/${transactionId}`,
    }),
    getTransactions: builder.query<
      TransactionList,
      { organizationId: Organization['id']; page: RequestPageParams }
    >({
      providesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Transactions' },
      ],
      query: ({ organizationId, page }) => {
        const queryStringParams = getQueryStringFromParams({
          page,
        });
        return `organizations/${organizationId}/platform/transactions${queryStringParams}`;
      },
    }),
    getWallets: builder.query<
      Wallets,
      { organizationId: Organization['id']; page?: RequestPageParams }
    >({
      providesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Wallets' },
      ],
      query: ({ organizationId, page }) => {
        const queryStringParams = getQueryStringFromParams({
          page,
        });
        return `organizations/${organizationId}/platform/wallets${queryStringParams}`;
      },
    }),
  }),
  reducerPath: `EnvironmentApi`,
  tagTypes: [
    'BankAccounts',
    'EnvironmentAccount',
    'TradingPairs',
    'Identities',
    'Keys',
    'Quotes',
    'Transactions',
    'TransactionSummaries',
    'Wallets',
  ],
});

export const {
  useCreateBankAccountMutation,
  useCreateIdentityMutation,
  useCreateKeyMutation,
  useCreateQuoteMutation,
  useCreateTransactionMutation,
  useDeleteBankAccountMutation,
  useDeleteIdentityMutation,
  useGetBankAccountsQuery,
  useGetEnvironmentAccountQuery,
  useGetTradingPairsQuery,
  useGetIdentitiesQuery,
  useGetKeysQuery,
  useGetQuoteQuery,
  useGetTransactionQuery,
  useGetTransactionSummaryQuery,
  useGetTransactionsQuery,
  useGetTransactionSummariesQuery,
  useGetWalletsQuery,
  useLazyGetBankAccountsQuery,
  useLazyGetIdentitiesQuery,
} = platformApi;
