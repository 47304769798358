import React, { FC } from 'react';

import { SlideoutTransactionDetailsProps } from 'components/dedicated/SlideoutTransactionDetails/SlideoutTransactionDetails.types';
import Box from 'components/core/Box/Box';
import TransactionDetails from 'components/dedicated/TransactionDetails/TransactionDetails';

import SlideoutTransactionDetailsHeader from './SlideoutTransactionDetailsHeader/SlideoutTransactionDetailsHeader';

const SlideoutTransactionDetails: FC<SlideoutTransactionDetailsProps> = ({
  organizationId,
  transactionSummary,
}) => {
  return (
    <>
      <SlideoutTransactionDetailsHeader transactionSummary={transactionSummary} />
      <Box padding={6}>
        <TransactionDetails
          organizationId={organizationId}
          transactionSummary={transactionSummary}
        />
      </Box>
    </>
  );
};

export default SlideoutTransactionDetails;
