import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import React, { FC } from 'react';
import cx from 'classnames';

import { TabProps } from './Tab.types';
import styles from './Tab.module.scss';

const Tab: FC<TabProps> = ({
  className,
  dataTestId,
  count,
  isActive,
  label,
  onClick,
  to,
  variant,
}) => {
  let isMatchedPath = false;
  let Component;
  if (to) {
    Component = NavLink;
    const resolvedPath = useResolvedPath(to || '');
    isMatchedPath = !!useMatch({ end: true, path: resolvedPath.pathname });
  } else {
    Component = 'button';
  }

  return (
    <Component
      className={cx(
        styles[`variant--${variant}`],
        styles.root,
        (isMatchedPath || isActive) && styles.isActive,
        className,
      )}
      data-testid={dataTestId}
      onClick={isActive ? undefined : onClick}
      to={to}
    >
      {label}
      {count !== undefined && ` (${count})`}
    </Component>
  );
};

export default Tab;
