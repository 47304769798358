import { TransactionStatus } from 'store/api/api.types';

export const TRANSACTIONS_FILTER_PENDING = [
  TransactionStatus.Created,
  TransactionStatus.Pending,
  TransactionStatus.PendingFunding,
  TransactionStatus.Funding,
  TransactionStatus.Settling,
  TransactionStatus.Screening,
  TransactionStatus.Refunding,
].join(',');

export const TRANSACTIONS_FILTER_COMPLETED = [
  TransactionStatus.Posted,
  TransactionStatus.Rejected,
  TransactionStatus.RejectedCompliance,
  TransactionStatus.Blocked,
  TransactionStatus.Canceled,
].join(',');
