import { useSearchParams } from 'react-router-dom';
import React, { ReactElement, useMemo } from 'react';

import { FINANCIAL_EMAIL } from 'constants/constants';
import { conduitLogo } from 'components/core/Svg/illustrations';
import { formatAmount, getUriId, getUriIdAndChainId } from 'utils/format';
import { useGetTransactionSummaryQuery } from 'store/api/platformApi';
import LoaderView from 'components/views/LoaderView/LoaderView';
import Svg from 'components/core/Svg/Svg';

import styles from './PaymentReceiptView.module.scss';

const PaymentReceiptView = (): ReactElement => {
  const [params] = useSearchParams();

  const organizationId = useMemo(() => params.get('organizationId'), [params]);
  const transactionId = useMemo(() => params.get('transactionId'), [params]);

  if (!organizationId || !transactionId) {
    return <div>Organization & transaction need to be provided</div>;
  }

  const { data: transactionSummary, isLoading } = useGetTransactionSummaryQuery({
    organizationId,
    transactionId,
  });

  const attributes = useMemo(() => {
    return transactionSummary?.attributes;
  }, [transactionSummary]);

  const bank = useMemo(() => {
    return attributes?.destination?.bank;
  }, [attributes]);

  const sourceAmount = useMemo(() => {
    return `${formatAmount(attributes?.source)} ${getUriIdAndChainId(attributes?.source?.assetType)}`;
  }, [attributes]);

  const paymentAmount = useMemo(() => {
    return `${formatAmount(attributes?.destination)} ${getUriId(attributes?.destination?.assetType)}`;
  }, [attributes]);

  if (isLoading) {
    return <LoaderView />;
  }

  return (
    <div className={styles.page}>
      <header>
        <div className={styles.conduit}>
          <Svg className={styles.logo} img={conduitLogo} size={[15.5, 3.75]} />
          <div>
            <b>Conduit Technology, Inc.</b>
            <br />
            1001 S. Main Street, Suite 4080
            <br />
            Kalispell, MT 59901
          </div>
          <a href={`mailto:${FINANCIAL_EMAIL}`}>{FINANCIAL_EMAIL}</a>
        </div>
        <div className={styles.client}>
          <table>
            <tr>
              <th>Client</th>
              <td>{attributes?.clientName}</td>
            </tr>
            <tr>
              <th>Payment Date</th>
              <td>{attributes?.effectiveAt}</td>
            </tr>
          </table>
        </div>
      </header>

      <h2>Payment Details</h2>

      <table className={styles.dataTable}>
        <tr>
          <th>Payment ID</th>
          <td>{getUriId(transactionId)}</td>
        </tr>
        <tr>
          <th>Recipient Name</th>
          <td>{bank?.nameOnBankAccount}</td>
        </tr>
        <tr>
          <th>Bank Name</th>
          <td>{`${bank?.bankName} (${bank?.accountNumber.slice(-4)})`}</td>
        </tr>
        <tr>
          <th>Payment Amount</th>
          <td>{paymentAmount}</td>
        </tr>
        <tr>
          <th>Source Amount</th>
          <td>{sourceAmount}</td>
        </tr>
      </table>

      <footer>© 2021 - 2024 Conduit Technology, Inc. All rights reserved</footer>
    </div>
  );
};

export default PaymentReceiptView;
