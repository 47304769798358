import { generatePath, useLocation, useParams } from 'react-router-dom';
import React, { ReactElement, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { NO_PERMISSION_MESSAGE } from 'constants/constants';
import { ORGANIZATION_MEMBERS_ROUTES } from 'router/constants';
import { TabProps } from 'components/core/Tab/Tab.types';
import { useCurrentUser } from 'context/CurrentUserContext';
import Button from 'components/core/Button/Button';
import ContentSection from 'components/core/ContentSection/ContentSection';
import InvitesTable from 'components/dedicated/organization/InvitesTable/InvitesTable';
import MembersTable from 'components/dedicated/organization/MembersTable/MembersTable';
import ModalInviteMember, {
  modalInviteMemberTitle,
} from 'components/dedicated/ModalInviteMember/ModalInviteMember';
import PageHeader from 'components/core/PageHeader/PageHeader';
import Tabs from 'components/core/Tabs/Tabs';
import Tooltip from 'components/core/Tooltip/Tooltip';
import modalService from 'services/modalService';

import styles from './MembersView.module.scss';

const MembersView = (): ReactElement => {
  const { pathname } = useLocation();
  const { organizationId } = useParams() as { organizationId: string };
  const { isUserAllowedTo } = useCurrentUser();

  const tableMode = useMemo(() => {
    return pathname.includes(ORGANIZATION_MEMBERS_ROUTES.INVITED.relative) ? 'invited' : 'active';
  }, [pathname]);

  const canCreateInvite = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.CreateInvite),
    [organizationId, isUserAllowedTo],
  );

  const tabs: TabProps[] = [
    {
      label: 'Active',
      to: generatePath(ORGANIZATION_MEMBERS_ROUTES.ACTIVE.absolute, {
        organizationId,
      }),
    },
    {
      label: 'Invited',
      to: generatePath(ORGANIZATION_MEMBERS_ROUTES.INVITED.absolute, {
        organizationId,
      }),
    },
  ];

  return (
    <ContentSection>
      <PageHeader className={styles.pageHeader} title='Members'>
        <Tooltip isHidden={canCreateInvite} label={NO_PERMISSION_MESSAGE}>
          <Button
            isDisabled={!canCreateInvite}
            label='Invite Member'
            onClick={() =>
              modalService.openModal(
                {
                  disableScrollOnLargeScreen: true,
                  title: modalInviteMemberTitle,
                },
                <ModalInviteMember organizationId={organizationId} />,
              )
            }
            size='small'
            type='submit'
            variant='primary'
          />
        </Tooltip>
      </PageHeader>
      <Tabs tabs={tabs} />
      {tableMode === 'invited' ? (
        <InvitesTable organizationId={organizationId} />
      ) : (
        <MembersTable organizationId={organizationId} />
      )}
    </ContentSection>
  );
};

export default MembersView;
