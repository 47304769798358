import React, { FC } from 'react';
import cx from 'classnames';

import Dot from 'components/core/Dot/Dot';

import { TransactionStatusBadgeProps } from './TransactionStatusBadge.types';
import { getAttributesByStatus } from './TransactionStatusBadge.helpers';
import styles from './TransactionStatusBadge.module.scss';

const TransactionStatusBadge: FC<TransactionStatusBadgeProps> = ({
  className,
  dataTestId,
  status,
  size = 'normal',
  variant = 'default',
}) => {
  const { color, showDot, label } = getAttributesByStatus(status);
  return (
    <div
      className={cx(
        styles.root,
        styles[`color--${color}`],
        styles[`status--${status}`],
        styles[`size--${size}`],
        styles[`variant--${variant}`],
        className,
      )}
      data-testid={dataTestId}
    >
      {showDot && <Dot className={styles.dot} color={color} size={size} />}
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default TransactionStatusBadge;
