import { DotProps } from 'components/core/Dot/Dot.types';
import { TransactionStatus } from 'store/api/api.types';

export const getAttributesByStatus = (
  status: TransactionStatus,
): { color: DotProps['color']; label: string; showDot?: boolean } => {
  switch (status) {
    case TransactionStatus.PendingFunding:
    case TransactionStatus.Refunding:
      return {
        color: 'orange',
        label: 'Awaiting Funds',
        showDot: true,
      };
    case TransactionStatus.Funding:
      return {
        color: 'violet',
        label: 'Payment Initiated',
        showDot: true,
      };
    case TransactionStatus.Rejected:
    case TransactionStatus.RejectedCompliance:
    case TransactionStatus.Blocked:
      return {
        color: 'red',
        label: 'Compliance Rejected',
      };
    case TransactionStatus.Canceled:
      return {
        color: 'gray',
        label: 'Canceled',
      };
    case TransactionStatus.Screening:
      return {
        color: 'yellow',
        label: 'In Review',
        showDot: true,
      };
    case TransactionStatus.Created:
    case TransactionStatus.Pending:
    case TransactionStatus.Settling:
      return {
        color: 'blue',
        label: 'Processing',
        showDot: true,
      };
    case TransactionStatus.Posted:
    default:
      return {
        color: 'green',
        label: 'Completed',
      };
  }
};
