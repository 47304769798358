import { Link, NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import React, { Ref, forwardRef } from 'react';
import cx from 'classnames';

import { spacingBase } from 'styles/_layout';
import Spinner from 'components/core/Spinner/Spinner';

import { ButtonProps } from './Button.types';
import styles from './Button.module.scss';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      align,
      children,
      className,
      dataTestId,
      display = 'block',
      href,
      Icon,
      IconLeft,
      iconPosition = 'left',
      iconRef,
      isActive,
      isDisabled = false,
      isLoading,
      isNavlink = false,
      label,
      marginBottom,
      marginTop,
      onClick,
      rel,
      size = 'normal',
      target,
      to,
      type = 'button',
      variant = 'primary',
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const style = {
      display: display !== 'block' ? display : undefined,
      marginBottom: marginBottom ? `calc(${marginBottom}*${spacingBase})` : undefined,
      marginTop: marginTop ? `calc(${marginTop}*${spacingBase})` : undefined,
    };

    const filteredProps = { style };

    const isActionDisabled = isDisabled || isLoading;
    let isMatchedPath = false;

    let Component;
    if (isNavlink) {
      Component = NavLink;
      const resolvedPath = useResolvedPath(to || '');
      isMatchedPath = !!useMatch({ end: true, path: resolvedPath.pathname });
      Object.assign(filteredProps, { to });
    } else if (to) {
      Component = Link;
      Object.assign(filteredProps, { to });
    } else if (href) {
      Component = 'a';
      Object.assign(filteredProps, { href, rel, target });
    } else {
      Component = 'button';
      Object.assign(filteredProps, {
        disabled: isActionDisabled,
        type,
      });
    }

    return (
      <Component
        ref={ref}
        className={cx(
          styles.button,
          styles[`align--${align}`],
          styles[`size--${size}`],
          styles[`variant--${variant}`],
          !label && !children && Icon && styles.hasIconOnly,
          isDisabled && styles.isDisabled,
          isLoading && styles.isLoading,
          (isMatchedPath || isActive) && styles.isActive,
          className,
        )}
        data-testid={dataTestId}
        disabled={isDisabled || isLoading}
        onClick={isActionDisabled ? () => {} : onClick}
        {...filteredProps}
      >
        {isLoading && (
          <Spinner
            className={styles.spinner}
            color='auto'
            dataTestId='Button__spinner'
            size='small'
          />
        )}
        {IconLeft && (
          <span
            ref={iconRef}
            className={cx(
              styles.iconContainer,
              styles[`iconPosition--left`],
              (label || children) && styles.isNextToContent,
            )}
          >
            {IconLeft}
          </span>
        )}
        {Icon && (
          <span
            ref={iconRef}
            className={cx(
              styles.iconContainer,
              styles[`iconPosition--${iconPosition}`],
              (label || children) && styles.isNextToContent,
            )}
          >
            {Icon}
          </span>
        )}
        <span className={styles.label}>
          {label}
          {children}
        </span>
      </Component>
    );
  },
);

export default Button;
