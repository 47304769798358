import React, { FC, useMemo } from 'react';

import { BadgeProps } from 'components/core/Badge/Badge.types';
import { BankAccount, Organization } from 'store/api/api.types';
import { IconProps } from 'components/core/Icon/Icon.types';
import Badge from 'components/core/Badge/Badge';
import Icon from 'components/core/Icon/Icon';
import Spinner from 'components/core/Spinner/Spinner';
import Tooltip from 'components/core/Tooltip/Tooltip';
import modalService from 'services/modalService';

import styles from './IdentityBankAccount.module.scss';

import { getBankTitle } from '../../../config/bankDetails.helpers';
import ModalBankAccountDetails from '../../../ModalBankAccountDetails/ModalBankAccountDetails';

const IdentityBankAccount: FC<{
  bankAccount: BankAccount;
  organizationId: Organization['id'];
  parentReload: () => void;
}> = ({ bankAccount, organizationId, parentReload }) => {
  const flagIcon = useMemo(
    () => `assetType${bankAccount.attributes.currency}` as IconProps['iconName'],
    [bankAccount],
  );

  const { color, label, tooltip, statusIcon } = useMemo(() => {
    let bankColor = 'grayLightest' as BadgeProps['color'];
    let bankLabel = '';
    let bankTooltip = '';
    let bankStatusIcon = <Icon iconName='pencil' size={1.4} />;

    if (!bankAccount.attributes.isEnabledToReceiveFunds) {
      if (!bankAccount.attributes.isEnabledToSendFunds) {
        bankColor = 'yellow';
        bankTooltip =
          'This bank account is finalizing setup and will be ready for transactions shortly.';
        bankStatusIcon = <Spinner color='auto' size='x-small' />;
      } else {
        bankColor = 'grayLighter';
        bankLabel = ' [Sender Only]';
      }
    }

    return {
      color: bankColor,
      label: bankLabel,
      statusIcon: bankStatusIcon,
      tooltip: bankTooltip,
    };
  }, [bankAccount]);

  return (
    <Tooltip isHidden={tooltip === ''} label={tooltip}>
      <Badge
        className={styles.badge}
        color={color}
        onClick={() =>
          modalService.openModal(
            { title: 'Bank Details' },
            <ModalBankAccountDetails
              bankAccount={bankAccount}
              organizationId={organizationId}
              parentReload={parentReload}
            />,
          )
        }
      >
        <Icon iconName={flagIcon} size={1.8} />
        <span>
          {getBankTitle(bankAccount.attributes)}
          {label}
        </span>
        {statusIcon}
      </Badge>
    </Tooltip>
  );
};

export default IdentityBankAccount;
