import { spacingBase } from 'styles/_layout';

/**
 * Generates a string of space-separated values representing CSS
 * lengths in "rem". Each input number is multiplied by a base
 * spacing unit to generate the corresponding CSS length.
 *
 * @param {...number} numbers An array of numbers to convert to CSS lengths.
 * @returns {string} A string of space-separated CSS lengths in "rem".
 *
 * @example
 * Assuming spacingBase is '0.4rem':
 *
 * // returns '1.6rem'
 * spacing(4);
 *
 * // returns '1.6rem 3.2rem 3.2rem 0rem'
 * spacing(4, 8, 8, 0);
 */
export function spacing(...numbers: number[]): string {
  const parsedList: string[] = [];
  numbers.forEach(number => {
    parsedList.push(`${number * parseFloat(spacingBase)}rem`);
  });
  return parsedList.join(' ');
}
