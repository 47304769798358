import React, { FC } from 'react';

import Text from 'components/core/Text/Text';

import { LoaderTextProps } from './LoaderText.types';
import styles from './LoaderText.module.scss';

const LoaderText: FC<LoaderTextProps> = ({ label }) => (
  <Text className={styles.loading} variant='bodyCopySmall'>
    {label}
  </Text>
);

export default LoaderText;
