import React, { FC } from 'react';
import cx from 'classnames';

import { copy } from 'components/core/Svg/icons';
import { spacing } from 'utils/spacing';
import Svg from 'components/core/Svg/Svg';
import useTimeoutState from 'hooks/useTimeoutState';

import CopyToClipboardProps from './CopyToClipboardButton.types';
import styles from './CopyToClipboardButton.module.scss';

const CopyToClipboardButton: FC<CopyToClipboardProps> = ({
  className,
  dataTestId,
  marginLeft = 4,
  messagePosition = 'right',
  size = 1.5,
  valueToCopy,
}) => {
  const [showMessage, setShowMessage] = useTimeoutState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(valueToCopy);
    setShowMessage(true, { timeout: 1000 });
  };

  return (
    <span className={styles.root} style={{ marginLeft: spacing(marginLeft) }}>
      {showMessage && (
        <div className={cx(styles.message, styles[`messagePosition--${messagePosition}`])}>
          Copied
        </div>
      )}
      <Svg
        className={cx(styles.icon, className)}
        dataTestId={dataTestId}
        img={copy}
        onClick={handleClick}
        size={size}
      />
    </span>
  );
};
export default CopyToClipboardButton;
