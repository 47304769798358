import React, { FC, useMemo } from 'react';

import { Identity, Organization } from 'store/api/api.types';
import { isGenericErrorType } from 'store/api/api.utils';
import { showError, showSuccess } from 'services/notificationService';
import { useDeleteIdentityMutation } from 'store/api/platformApi';
import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

const ModalIdentityDelete: FC<{
  identity: Identity;
  organizationId: Organization['id'];
  parentReload: () => void;
}> = ({ identity, organizationId, parentReload }) => {
  const [IdentityDelete, { isLoading: isDeleting }] = useDeleteIdentityMutation();

  const identityTitle = useMemo(() => `${identity.businessName}`, [identity]);

  const handleDelete = async e => {
    e.preventDefault();

    const result = await IdentityDelete({ identityId: identity.id, organizationId });
    if (!('error' in result)) {
      showSuccess(`You have successfully deleted ${identityTitle}.`);
      modalService.closeCurrentModal();
    } else if (isGenericErrorType(result.error)) {
      showError(
        `${identityTitle} was unable to be successfully deleted. Please try again. If the issue continues, please contact support.`,
      );
    }
    parentReload();
    modalService.closeCurrentModal();
  };
  return (
    <>
      <ModalContent variant='noBottomPadding'>
        <Text marginBottom={4}>
          Are you sure you want to delete <b>{identityTitle}</b>?
        </Text>
        <Text marginBottom={4}>
          This sender/recipient will be unavailable for future transactions. Your existing
          transactions will be unaffected.
        </Text>
      </ModalContent>
      <ModalContent variant='footerButtons'>
        <Button
          label='Cancel'
          onClick={() => modalService.closeCurrentModal()}
          variant='secondary'
        />
        <Button
          isLoading={isDeleting}
          label='Delete'
          onClick={handleDelete}
          variant='destructive'
        />
      </ModalContent>
    </>
  );
};

export default ModalIdentityDelete;
