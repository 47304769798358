import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import React, { FC, useEffect } from 'react';

import { SendMoneyOutletContext } from 'components/views/app/organization/SendMoney/SendMoney.types';
import { formatAssetType, getUriIdAndChainId, isFiat } from 'utils/format';
import { isFetchBaseQueryErrorType, isGenericErrorType } from 'store/api/api.utils';
import { showError } from 'services/notificationService';
import { useCreateTransactionContext } from 'context/CreateTransactionContext';
import { useCreateTransactionMutation } from 'store/api/platformApi';
import Button from 'components/core/Button/Button';
import List from 'components/core/List/List';
import ListItem from 'components/core/ListItem/ListItem';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';

import { QuotePreviewProps } from './QuotePreview.types';
import styles from './QuotePreview.module.scss';

import { transactionPurposeLabels } from '../QuoteForm/QuoteForm.constants';

const QuotePreview: FC<QuotePreviewProps> = ({ onCancel }) => {
  const navigate = useNavigate();
  const { accountId, assetTypes } = useOutletContext<SendMoneyOutletContext>();
  const { organizationId } = useParams() as { organizationId: string };
  const {
    supportingDocumentFile,
    quote,
    quoteStatusLabel,
    transactionPurpose,
    recipientLabel,
    senderLabel,
    isQuoteInProgress,
    reference,
  } = useCreateTransactionContext();
  const [quoteDetails, setQuoteDetails] = React.useState<{
    actionDescription: string;
    amountToReceive: string;
    amountToSend: string;
  }>({
    actionDescription: '',
    amountToReceive: '',
    amountToSend: '',
  });

  const [createTransaction, { isLoading: isAcceptingQuote }] = useCreateTransactionMutation();

  useEffect(() => {
    if (!isQuoteInProgress) {
      setQuoteDetails({
        actionDescription:
          quote?.attributes.source.assetType.indexOf('fiat') === -1
            ? `Upon confirmation, ${getUriIdAndChainId(quote.attributes.source.assetType)} will be deducted from your balance and ${getUriIdAndChainId(quote?.attributes.target.assetType)} will be sent to the recipient's bank account.`
            : `Upon confirmation, the quote will be locked and you'll receive detailed instructions for sending ${getUriIdAndChainId(quote?.attributes.source.assetType)}.`,
        amountToReceive: formatAssetType(quote?.attributes.target),
        amountToSend: formatAssetType(quote?.attributes.source),
      });
    }
  }, [isQuoteInProgress, quote]);

  const acceptQuoteOnRamp = async () => {
    const result = await createTransaction({
      amount: quote!.attributes.target.amount,
      assetType: quote!.attributes.target.assetType,
      destination: accountId!,
      file: supportingDocumentFile,
      organizationId,
      purpose: transactionPurpose,
      quote: quote!.id,
      reference,
      source: quote!.attributes.sourceBankId || '',
    });
    if (!('error' in result)) {
      navigate(result.data.id);
    } else if (isGenericErrorType(result.error)) {
      showError('Transaction cannot be confirmed. Please try again later.');
    } else if (isFetchBaseQueryErrorType(result.error)) {
      const errorData: any = result.error.data;
      showError(errorData.errors[0].title);
    }
  };

  const acceptQuoteOffRamp = async () => {
    const allocationId = assetTypes.find(
      a => a.id === quote!.attributes.source.assetType,
    )?.allocationId;

    if (allocationId) {
      const result = await createTransaction({
        amount: quote!.attributes.source.amount,
        assetType: quote!.attributes.source.assetType,
        destination: quote!.attributes.targetBankId || '',
        file: supportingDocumentFile,
        organizationId,
        purpose: transactionPurpose,
        quote: quote!.id,
        reference,
        source: allocationId,
      });
      if (!('error' in result)) {
        navigate(result.data.id);
      } else if (isGenericErrorType(result.error)) {
        showError('Transaction cannot be confirmed. Please try again later.');
      } else if (isFetchBaseQueryErrorType(result.error)) {
        const errorData: any = result.error.data;
        showError(errorData.errors[0].title);
      }
    } else {
      showError('Transaction cannot be confirmed. Missing allocation.');
    }
  };

  const acceptQuoteFx = async () => {
    const result = await createTransaction({
      amount: quote!.attributes.target.amount,
      assetType: quote!.attributes.target.assetType,
      destination: quote!.attributes.targetBankId || '',
      file: supportingDocumentFile,
      organizationId,
      purpose: transactionPurpose,
      quote: quote!.id,
      reference,
      source: quote!.attributes.sourceBankId || '',
    });
    if (!('error' in result)) {
      navigate(result.data.id);
    } else if (isGenericErrorType(result.error)) {
      showError('Transaction cannot be confirmed. Please try again later.');
    } else if (isFetchBaseQueryErrorType(result.error)) {
      const errorData: any = result.error.data;
      showError(errorData.errors[0].title);
    }
  };

  const acceptQuote = async () => {
    if (quote) {
      if (isFiat(quote.attributes.source.assetType) && isFiat(quote.attributes.target.assetType)) {
        acceptQuoteFx();
      } else if (quote.attributes.targetBankId) {
        acceptQuoteOffRamp();
      } else {
        acceptQuoteOnRamp();
      }
    } else {
      showError('Transaction cannot be confirmed. Missing quote.');
    }
  };

  return (
    <>
      <Text align='center' variant='sectionHeaderLarge'>
        Review & Confirm
      </Text>
      <Text align='center' marginBottom={5} marginTop={5} variant='bodyCopySmall'>
        {quoteDetails.actionDescription}
      </Text>
      <List className={styles.list}>
        <ListItem label='Send'>
          {!isQuoteInProgress ? (
            quoteDetails.amountToSend
          ) : (
            <Spinner display='inline' size='small' />
          )}
        </ListItem>
        <ListItem label='Receive'>
          {!isQuoteInProgress ? (
            quoteDetails.amountToReceive
          ) : (
            <Spinner display='inline' size='small' />
          )}
        </ListItem>
        {senderLabel && <ListItem label='Sender'>{senderLabel}</ListItem>}
        {recipientLabel && <ListItem label='Recipient'>{recipientLabel}</ListItem>}
        {transactionPurpose && (
          <ListItem label='Purpose'>{transactionPurposeLabels[transactionPurpose]}</ListItem>
        )}
        {supportingDocumentFile && (
          <ListItem label='Document'>{supportingDocumentFile.name}</ListItem>
        )}
        {reference && <ListItem label='Reference'>{reference}</ListItem>}
      </List>
      <div className={styles.footer}>
        <Text align='center' marginBottom={5} marginTop={5} variant='bodyCopySmall'>
          {quoteStatusLabel}
        </Text>
        <div className={styles.buttons}>
          <Button
            isDisabled={isAcceptingQuote}
            label='Go Back'
            onClick={onCancel}
            type='button'
            variant='secondary'
          />
          <Button
            isDisabled={isAcceptingQuote}
            label='Confirm'
            onClick={acceptQuote}
            type='submit'
            variant='primary'
          />
        </div>
      </div>
    </>
  );
};

export default QuotePreview;
