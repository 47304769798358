import React, { FC } from 'react';

import { PRINT_ROUTES } from 'router/constants';
import { TransactionStatus, TransactionType } from 'store/api/api.types';
import { apiUrl } from 'env';
import { getParsedTransactionItemAttributes } from 'components/dedicated/TransactionItem/TransactionItem.helpers';
import { getUriChain } from 'utils/format';
import { transactionPurposeLabels } from 'components/dedicated/organization/send-money/QuoteForm/QuoteForm.constants';
import Box from 'components/core/Box/Box';
import DepositInstructions from 'components/dedicated/organization/TransactionDetail/DepositInstructions/DepositInstructions';
import FileDownloadLink from 'components/core/FileDownloadLink/FileDownloadLink';
import Text from 'components/core/Text/Text';
import TextWithTooltip from 'components/core/TextWithTooltip/TextWithTooltip';

import { DetailsTable, TransactionDetailsProps } from './TransactionDetails.types';
import styles from './TransactionDetails.module.scss';

const TransactionDetails: FC<TransactionDetailsProps> = ({
  className,
  organizationId,
  transactionSummary,
  ...props
}) => {
  const { attributes, id: transactionId } = transactionSummary;
  const { blockchainTxHash, createdAt, reference, purpose, documents, source, destination } =
    attributes;
  const {
    sender,
    sourceAmount,
    sourceAsset,
    recipient,
    targetAsset,
    targetAmount,
    status,
    transactionType,
  } = getParsedTransactionItemAttributes(attributes);

  const transactionDetails: DetailsTable = [
    {
      label: 'Transaction ID',
      value: transactionSummary.id,
    },
    {
      label: 'Date Created',
      value: createdAt,
    },
    {
      label: 'From',
      value: (
        <div>
          <Text marginBottom={1} variant='labelSmall'>
            {sourceAmount} {sourceAsset}
          </Text>
          <Text variant='subCopySmall'>{sender}</Text>
        </div>
      ),
    },
    {
      label: 'To',
      value: (
        <div>
          <Text marginBottom={1} variant='labelSmall'>
            {targetAmount} {targetAsset}
          </Text>
          <Text variant='subCopySmall'>{recipient}</Text>
        </div>
      ),
    },
    {
      label: 'Reference',
      value: reference,
    },
  ];

  const purposeAndDocumentation: DetailsTable = [
    {
      label: 'Purpose',
      value: purpose ? transactionPurposeLabels[purpose] : null,
    },
    {
      label: 'Supporting Document',
      value: documents?.invoice?.fileName ? (
        <FileDownloadLink
          fileName={documents.invoice.fileName}
          url={`${apiUrl}/organizations/${organizationId}/platform/transactions/${transactionId}/documents/${documents.invoice.id}`}
          variant='bodyLinkSmall'
        />
      ) : null,
    },
  ];

  const trackingAndReceipts: DetailsTable = [
    {
      label: 'Receipt',
      value:
        status === TransactionStatus.Posted && ['offramp', 'fx'].includes(transactionType) ? (
          <Text
            onClick={() =>
              window.open(
                `${PRINT_ROUTES.PAYMENT_RECEIPTS.absolute}?organizationId=${organizationId}&transactionId=${transactionId}`,
                '_blank',
                'noopener,noreferrer',
              )
            }
            variant='bodyLinkSmall'
          >
            View Payment Receipt
          </Text>
        ) : null,
    },
  ];

  if ([TransactionType.Deposit, TransactionType.Withdrawal].includes(transactionType)) {
    let transactionHashValue;
    if (!blockchainTxHash) {
      transactionHashValue = (
        <TextWithTooltip
          text='This is not available yet.'
          tooltipText='This will become available once we’ve completed your transaction.'
        />
      );
    } else {
      const chain = getUriChain(source.assetType || destination.assetType);
      if (chain) {
        transactionHashValue = (
          <Text
            onClick={() =>
              window.open(
                `${chain.explorerUrl}${blockchainTxHash}`,
                '_blank',
                'noopener,noreferrer',
              )
            }
            variant='bodyLinkSmall'
          >
            {blockchainTxHash}
          </Text>
        );
      }
    }

    if (transactionHashValue) {
      trackingAndReceipts.unshift({
        label: 'Transaction Hash',
        value: transactionHashValue,
      });
    }
  }

  const tables = [
    {
      data: transactionDetails,
      title: 'Transaction Details',
    },
    {
      data: purposeAndDocumentation,
      title: 'Purpose and Documentation',
    },
    {
      data: trackingAndReceipts,
      title: 'Tracking and Receipts',
    },
  ];

  return (
    <Box className={className} {...props}>
      {tables.map(({ title, data }) => {
        if (!data.some(({ value }) => value)) {
          return null;
        }
        return (
          <Box key={title} marginBottom={12}>
            <Text marginBottom={6} variant='sectionHeaderStandard'>
              {title}
            </Text>
            {data.map(
              ({ label, value }) =>
                value && (
                  <Box
                    key={label}
                    alignItems='flex-start'
                    display='flex'
                    justifyContent='space-between'
                    marginBottom={6}
                  >
                    <Text className={styles.label} Tag='div' variant='labelSmallSubLabel'>
                      {label}
                    </Text>
                    <Text align='right' className={styles.value} variant='bodyCopySmall'>
                      {value}
                    </Text>
                  </Box>
                ),
            )}
          </Box>
        );
      })}
      {status === TransactionStatus.Pending && ['onramp', 'fx'].includes(transactionType) && (
        <Box marginBottom={12}>
          <Text marginBottom={4} variant='sectionHeaderStandard'>
            Deposit Instructions
          </Text>
          <Text marginBottom={4} variant='subCopySmall'>
            We&apos;re waiting for your funds to be sent and arrive at our bank. Once received,
            we&apos;ll promptly process the transaction. Funds settled before 4 PM ET are processed
            the same day; after 4 PM ET, they&apos;re processed the next banking day.
          </Text>
          <DepositInstructions transactionAttributes={attributes} />
        </Box>
      )}
    </Box>
  );
};

export default TransactionDetails;
