import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import Select, { ActionMeta, MultiValue, SelectInstance, SingleValue } from 'react-select';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';

import { zindexFormOverlayElement } from 'styles/_layout';
import FormErrorMessage from 'components/core/Form/FormErrorMessage/FormErrorMessage';
import generateUid from 'utils/generateUid';

import { SelectFieldOption, SelectFieldProps } from './SelectField.types';
import OptionComponent from './SelectFieldOption/SelectFieldOption';
import SelectFieldDropdownIndicator from './SelectFieldDropdownIndicator/SelectFieldDropdownIndicator';
import SelectFieldSingleValue from './SelectFieldSingleValue/SelectFieldSingleValue';
import SelectFieldValueContainer from './SelectFieldValueContainer/SelectFieldValueContainer';
import styles from './SelectField.module.scss';

function SelectField<T extends SelectFieldProps>(
  {
    className,
    dataTestId,
    error,
    isClearable = false,
    isDisabled = false,
    isHidden = false,
    isRequired = false,
    name,
    id,
    label,
    onChange,
    onSelect,
    options,
    placeholder,
    isSearchable = true,
    selectedDefault,
    showSublabelForSelected,
    useSublabelForSearch = true,
    size = 'normal',
    supportingText = '',
    value,
  }: T,
  ref,
) {
  const [isFocused, setIsFocused] = useState(false);
  const selectRef = useRef<SelectInstance<SelectFieldOption> | null>(null);
  useImperativeHandle(ref, () => selectRef.current);

  const fieldId = useMemo(() => {
    return id || generateUid();
  }, [id]);

  const [selectedOption, setSelectedOption] = useState<SelectFieldOption | null | undefined>(value);

  const filterList = useCallback(
    (option, rawInput) => {
      const { label: optionLabel, sublabel } = option.data;
      const input = (rawInput || '').toLowerCase();
      return (
        (optionLabel || '').toLowerCase().includes(input) ||
        (useSublabelForSearch && (sublabel || '').toLowerCase().includes(input))
      );
    },
    [useSublabelForSearch],
  );

  const handleOnSelect = useCallback(
    (
      option: MultiValue<SelectFieldOption> | SingleValue<SelectFieldOption> | null | undefined,
      actionMeta?: ActionMeta<SelectFieldOption>,
    ) => {
      // Rule for handling uncontrolled components with no default value.
      // Option can never be set to undefined by UI interaction.
      if (option === undefined) {
        return;
      }
      // Please note: multiple options selection support
      // is not implemented.
      let newOption: SelectFieldOption | null = option as SelectFieldOption;
      if (actionMeta?.action === 'clear') {
        newOption = null;
      }
      if (!isEqual(newOption, selectedOption)) {
        if (onSelect) {
          onSelect(newOption);
        }
        if (onChange) {
          const syntheticEvent = {
            target: {
              name: name || '',
              value: newOption?.value || '',
            },
          } as ChangeEvent<HTMLInputElement>;

          onChange(syntheticEvent);
        }
        setSelectedOption(newOption as SelectFieldOption);
      }
    },
    [name, onChange, onSelect, selectedOption],
  );

  // Handles the case when any of the value-determining props change.
  useEffect(() => {
    const determinedSelectedOption =
      // selectedOption === null check is used for the case when the value is cleared using clear action
      selectedOption === null
        ? null
        : options.find(option => isEqual(option, value)) ||
          options.find(option => isEqual(option, selectedOption)) ||
          options.find(option => isEqual(option, selectedDefault)) ||
          undefined;
    handleOnSelect(determinedSelectedOption);
  }, [options, selectedDefault, selectedOption, value, handleOnSelect]);

  const ValueDisplay = useCallback(
    props => (
      <SelectFieldSingleValue
        {...props}
        hasError={!!error}
        showSublabelForSelected={showSublabelForSelected}
      />
    ),
    [error, showSublabelForSelected],
  );

  const DropdownIndicator = useCallback(
    props => <SelectFieldDropdownIndicator {...props} hasError={!!error} />,
    [error],
  );

  return (
    <div className={cx(styles.root, isHidden && styles.isHidden, className)}>
      <div
        className={cx(
          styles.fieldWrapper,
          styles[`size--${size}`],
          selectedOption && styles.hasValue,
          isDisabled && styles.isDisabled,
          isFocused && styles.isFocused,
          error && styles.hasError,
        )}
      >
        <Select
          ref={selectRef}
          components={{
            DropdownIndicator,
            Option: OptionComponent,
            SingleValue: ValueDisplay,
            ValueContainer: SelectFieldValueContainer,
            ...(isClearable ? {} : { IndicatorSeparator: null }),
          }}
          data-testid={dataTestId}
          defaultValue={selectedOption}
          filterOption={filterList}
          id={id}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isMulti={false}
          isSearchable={isSearchable}
          menuPlacement='auto'
          onBlur={() => setIsFocused(false)}
          onChange={handleOnSelect}
          onFocus={() => setIsFocused(true)}
          options={options}
          placeholder={placeholder}
          required={isRequired}
          styles={{
            menu: base => ({ ...base, zIndex: zindexFormOverlayElement }),
          }}
          value={selectedOption}
        />
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>{label && <span>{label}</span>}</legend>
        </fieldset>
        {label && (
          <label className={styles.label} htmlFor={fieldId}>
            {label}
          </label>
        )}
      </div>
      {(supportingText || error) && (
        <div className={cx(styles.supportingTextWrapper, isDisabled && styles.forDisabledField)}>
          {error ? (
            <FormErrorMessage
              className={styles.errorMessage}
              dataTestId={`${name}-error`}
              message={error}
            />
          ) : (
            supportingText
          )}
        </div>
      )}
    </div>
  );
}

export default forwardRef(SelectField);
