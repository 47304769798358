import React, { FC } from 'react';

import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';

import styles from './LoaderView.module.scss';

const LoaderView: FC<{ label?: string }> = ({ label }) => (
  <div className={styles.root}>
    <Spinner size='large' />
    {label && (
      <Text className={styles.label} variant='bodyCopyStandard'>
        {label}
      </Text>
    )}
  </div>
);

export default LoaderView;
