import React, { FC, useMemo, useState } from 'react';

import {
  ApiTransactionSummaryAttributes,
  TransactionDepositInstructions,
  TransactionDepositInstructionsType,
} from 'store/api/api.types';
import { TabProps } from 'components/core/Tab/Tab.types';
import List from 'components/core/List/List';
import ListItem from 'components/core/ListItem/ListItem';
import Tabs from 'components/core/Tabs/Tabs';

import { getInstructionFields, getInstructionTypeTabLabel } from './DepositInstructions.utils';
import styles from './DepositInstructions.module.scss';

const DepositInstructions: FC<{
  transactionAttributes: ApiTransactionSummaryAttributes;
}> = ({ transactionAttributes }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const depositInstructions = useMemo(() => {
    if (transactionAttributes.ramp) {
      return transactionAttributes.ramp.depositInstructions || [];
    }

    if (transactionAttributes.fx) {
      return transactionAttributes.fx.payInInstructions || [];
    }

    return [];
  }, [transactionAttributes]);

  const instructions: TransactionDepositInstructions = useMemo(
    () => depositInstructions[selectedIndex < depositInstructions.length ? selectedIndex : 0],
    [depositInstructions, selectedIndex],
  );

  const instructionFields = useMemo(() => getInstructionFields(instructions), [instructions]);

  const tabs = useMemo((): TabProps[] => {
    return depositInstructions.map(
      (instruction, index) =>
        ({
          isActive: index === selectedIndex,
          label: getInstructionTypeTabLabel(instruction.type),
          onClick: () => {
            setSelectedIndex(index);
          },
        }) as TabProps,
    );
  }, [depositInstructions, selectedIndex]);

  return (
    <>
      {tabs.length > 1 && <Tabs tabs={tabs} variant='picker' />}
      {instructions?.type === TransactionDepositInstructionsType.PIX && (
        <img
          alt='QR Code'
          className={styles.qrCode}
          src={`data:image/png;base64,${instructions.qrCodeImageBase64}`}
        />
      )}
      {instructionFields.length > 0 && (
        <List>
          {instructionFields.map(({ label, props, value }) => (
            <ListItem key={label} label={label} valueForCopyButton={value} {...props}>
              {value}
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default DepositInstructions;
