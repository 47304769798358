import React, { FC } from 'react';
import cx from 'classnames';

import SpinnerProps from './Spinner.types';
import styles from './Spinner.module.scss';

const Spinner: FC<SpinnerProps> = ({
  className,
  color = 'default',
  dataTestId,
  display = 'centered',
  size = 'medium',
}) => (
  <div
    className={cx(
      styles.spinner,
      styles[`color--${color}`],
      styles[`display--${display}`],
      styles[`size--${size}`],
      className,
    )}
    data-testid={dataTestId}
  />
);

export default Spinner;
