import { generatePath, useNavigate, useParams } from 'react-router-dom';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';

import { ORGANIZATION_ROUTES } from 'router/constants';
import { PAGE_SIZE_OPTIONS } from 'components/core/PaginationBar/PaginationBar.constants';
import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';
import {
  TRANSACTIONS_FILTER_COMPLETED,
  TRANSACTIONS_FILTER_PENDING,
} from 'components/views/app/organization/TransactionsView/TransactionsView.constants';
import { useGetTransactionSummariesQuery } from 'store/api/platformApi';
import Button from 'components/core/Button/Button';
import ContentSection from 'components/core/ContentSection/ContentSection';
import PageHeader from 'components/core/PageHeader/PageHeader';
import PaginationBar from 'components/core/PaginationBar/PaginationBar';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';
import TransactionsList from 'components/dedicated/organization/TransactionsList/TransactionsList';
import usePaginationLinks from 'hooks/usePaginationLinks';

import styles from './TransactionsView.module.scss';

const TransactionsView = (): ReactElement => {
  const { organizationId } = useParams() as { organizationId: string };
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const queryParamPageAfter = params.get('pageAfter');
  const queryParamPageBefore = params.get('pageBefore');
  const queryParamSize = params.get('pageSize');
  const [pageSize, setPageSize] = useState<SelectFieldOption>(
    PAGE_SIZE_OPTIONS.find(
      option => option.value === (queryParamSize ? parseInt(queryParamSize, 10) : null),
    ) || PAGE_SIZE_OPTIONS[1],
  );
  const {
    data: transactionsSummaries,
    isLoading,
    isFetching,
  } = useGetTransactionSummariesQuery({
    organizationId,
    params: {
      page: {
        after: queryParamPageAfter || undefined,
        before: queryParamPageBefore || undefined,
        size: pageSize.value,
      },
    },
  });
  const { handleOnNextPage, handleOnPreviousPage } = usePaginationLinks(
    transactionsSummaries?.links,
    pageSize.value,
  );

  const pendingTransactions = useMemo(
    () =>
      transactionsSummaries?.data?.filter(transaction =>
        TRANSACTIONS_FILTER_PENDING.includes(transaction.attributes.status),
      ) || [],
    [transactionsSummaries],
  );

  const completedTransactions = useMemo(
    () =>
      transactionsSummaries?.data?.filter(transaction =>
        TRANSACTIONS_FILTER_COMPLETED.includes(transaction.attributes.status),
      ) || [],
    [transactionsSummaries],
  );

  const handleOnLimitSelect = useCallback(
    (option: SelectFieldOption | null) => {
      const newPageSize = option || PAGE_SIZE_OPTIONS[1];
      setPageSize(newPageSize);
      navigate(`${window.location.pathname}?pageSize=${newPageSize.value}`);
    },
    [navigate],
  );

  const transactionSummaries = transactionsSummaries?.data || [];

  return (
    <ContentSection className={styles.root}>
      {isLoading && <Spinner />}
      {!isLoading && transactionSummaries.length > 0 && (
        <>
          {isFetching && <Spinner />}
          {!isFetching && pendingTransactions.length > 0 && (
            <>
              <PageHeader title='Pending' />
              <TransactionsList
                organizationId={organizationId}
                transactionSummaries={pendingTransactions}
              />
            </>
          )}
          {!isFetching && completedTransactions.length > 0 && (
            <>
              <PageHeader
                marginTop={pendingTransactions.length > 0 ? 9 : undefined}
                title='Completed'
              />
              <TransactionsList
                organizationId={organizationId}
                shouldSectionByMonths
                transactionSummaries={completedTransactions}
              />
            </>
          )}
          <PaginationBar
            onLimitSelect={handleOnLimitSelect}
            onNextPage={handleOnNextPage}
            onPrevPage={handleOnPreviousPage}
            pageSize={pageSize}
            resultsCount={transactionSummaries.length}
          />
        </>
      )}
      {!isFetching && transactionSummaries.length === 0 && (
        <div className={styles.noResultsNotificationWrapper}>
          <Text align='center' variant='sectionHeaderLarge'>
            No Transactions Yet
          </Text>
          <Text align='center' className={styles.gray} marginTop={2}>
            It looks like you haven&apos;t made any transactions yet. Once a transaction is created,
            you’ll be able to track its progress and view all the important details right here.
          </Text>
          <Button
            align='center'
            marginTop={4}
            to={generatePath(ORGANIZATION_ROUTES.SEND_MONEY.absolute, {
              organizationId,
            })}
            variant='primary'
          >
            Send Money
          </Button>
        </div>
      )}
    </ContentSection>
  );
};

export default TransactionsView;
