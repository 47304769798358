import { toWei } from 'web3-utils';
import { useParams } from 'react-router-dom';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AssetType } from 'constants/assetTypes.types';
import { Quote, TransactionPurpose } from 'store/api/api.types';
import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';
import { formatAmount, getUriIdAndChainId, isFiat } from 'utils/format';
import { isCurrentTimeInRange } from 'utils/isCurrentTimeInRange';
import { showError } from 'services/notificationService';
import { useCreateQuoteMutation } from 'store/api/platformApi';

import { CreateTransactionContextType } from './CreateTransactionContext.types';
import CreateTransactionContext from './CreateTransactionContext';

const REFETCH_QUOTE_TICK = 1000;
const DELAY_BEFORE_FIRST_QUOTING = 1000;
let refetchQuoteTimeout: NodeJS.Timeout | number | undefined;

export const CreateTransactionContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const refetchQuoteTimeoutRef = useRef(refetchQuoteTimeout);
  const { organizationId } = useParams() as { organizationId: string };
  const [createQuote, { isLoading: isQuoteInProgress, data: createdQuoteData }] =
    useCreateQuoteMutation({
      fixedCacheKey: 'createQuote',
    });
  const secondsToUpdateQuoteRef = useRef<number>(0);
  const [secondsCounter, setSecondsCounter] = useState<number>(0);
  const [sourceAmount, setSourceAmount] = useState('');
  const [sourceAssetType, setSourceAssetType] = useState<AssetType | undefined>();
  const [isSourceAmountValid, setIsSourceAmountValid] = useState(true);
  const [targetAmount, setTargetAmount] = useState('');
  const [targetAssetType, setTargetAssetType] = useState<AssetType | undefined>();
  const [isTargetAmountValid, setIsTargetAmountValid] = useState(true);
  const [sender, setSender] = useState<SelectFieldOption | undefined>();
  const [recipient, setRecipient] = useState<SelectFieldOption | undefined>();
  const [transactionPurpose, setTransactionPurpose] = React.useState<TransactionPurpose>();
  const [supportingDocumentFile, setSupportingDocumentFile] = React.useState<File>();
  const [reference, setReference] = React.useState<string>('');

  const [amount, setAmount] = useState('');
  const [amountType, setAmountType] = useState('source');

  const manageQuoteRefresh = useCallback(async () => {
    if (amountType === 'none') {
      setTargetAmount('');
      setSourceAmount('');
    }

    if (!isQuoteInProgress && secondsToUpdateQuoteRef.current <= 1) {
      const sourceAmountOrUndefined = amountType === 'source' ? toWei(amount, 'mwei') : undefined;
      const targetAmountOrUndefined = amountType === 'target' ? toWei(amount, 'mwei') : undefined;

      const result = await createQuote({
        organizationId,
        sourceAmount: sourceAmountOrUndefined,
        sourceAssetType: sourceAssetType?.id || '',
        sourceBankId: sender?.value,
        targetAmount: targetAmountOrUndefined,
        targetAssetType: targetAssetType?.id || '',
        targetBankId: recipient?.value,
      });

      if (!('error' in result)) {
        const { data } = result;
        if (amountType === 'source') {
          setTargetAmount(formatAmount(data.attributes.target).toString());
        } else if (amountType === 'target') {
          setSourceAmount(formatAmount(data.attributes.source).toString());
        } else {
          setSourceAmount('');
          setTargetAmount('');
        }

        const createdAt = Date.parse(data.attributes.createdAt.toString());
        const expiresAt = Date.parse(data.attributes.expiresAt.toString());
        // Includes a 5 second tolerance
        const timeToUpdateQuoteExpiration = (expiresAt - createdAt) / 1000 - 5;
        secondsToUpdateQuoteRef.current = timeToUpdateQuoteExpiration;
      } else {
        showError(
          `We're unable to generate a quote at this time. Please refresh the page and try again. If the issue persists, contact support.`,
        );
        clearTimeout(refetchQuoteTimeoutRef.current);
        setSecondsCounter(0);
        return;
      }
    } else {
      secondsToUpdateQuoteRef.current -= 1;
    }
    setSecondsCounter(secondsToUpdateQuoteRef.current);
    refetchQuoteTimeoutRef.current = setTimeout(manageQuoteRefresh, REFETCH_QUOTE_TICK);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isQuoteInProgress,
    createQuote,
    sender?.value,
    recipient?.value,
    organizationId,
    amount,
    amountType,
  ]);

  const canCreateQuote = useMemo(
    () =>
      sourceAssetType?.id &&
      targetAssetType?.id &&
      amount !== '' &&
      amount !== '0' &&
      !amount.includes('-')[amount],
    [amount, sourceAssetType?.id, targetAssetType?.id],
  );

  useEffect(() => {
    let debouncedManageQuoteRefresh: NodeJS.Timeout | number | undefined;
    secondsToUpdateQuoteRef.current = 0;
    setSecondsCounter(secondsToUpdateQuoteRef.current);
    if (canCreateQuote) {
      debouncedManageQuoteRefresh = setTimeout(manageQuoteRefresh, DELAY_BEFORE_FIRST_QUOTING);
    }

    return () => {
      clearTimeout(debouncedManageQuoteRefresh);
      clearTimeout(refetchQuoteTimeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canCreateQuote, sourceAssetType, targetAssetType, amount]);

  const quote: Quote | undefined = useMemo(
    () =>
      createdQuoteData
        ? {
            attributes: {
              ...createdQuoteData.attributes,
              sourceBankId: sender?.value,
              targetBankId: recipient?.value,
            },
            id: createdQuoteData.id,
            type: createdQuoteData.type,
          }
        : undefined,
    [createdQuoteData, sender, recipient],
  );

  const quoteStatusLabel = useMemo(() => {
    if (isQuoteInProgress) {
      return 'Quote updating...';
    }
    if (secondsCounter > 0) {
      return `Quote updates in ${secondsCounter}s`;
    }
    return '';
  }, [isQuoteInProgress, secondsCounter]);

  const labels = useMemo(() => {
    let senderLabel = '';
    let recipientLabel = '';
    if (sourceAssetType) {
      senderLabel =
        isFiat(sourceAssetType) && sender
          ? `${sender.label} - ${sender.sublabel}`
          : `My ${getUriIdAndChainId(sourceAssetType)} Balance`;
    }
    if (targetAssetType) {
      recipientLabel =
        isFiat(targetAssetType) && recipient
          ? `${recipient.label} - ${recipient.sublabel}`
          : `My ${getUriIdAndChainId(targetAssetType)} Balance`;
    }
    return { recipientLabel, senderLabel };
  }, [recipient, sender, sourceAssetType, targetAssetType]);

  const isQuoteFormDisabled = useMemo(
    () =>
      !!(
        (sourceAssetType?.operatingHours &&
          !isCurrentTimeInRange(sourceAssetType.operatingHours)) ||
        (targetAssetType?.operatingHours && !isCurrentTimeInRange(targetAssetType.operatingHours))
      ),
    [sourceAssetType, targetAssetType],
  );

  const contextValue = useMemo(
    (): CreateTransactionContextType => ({
      isQuoteFormDisabled,
      isQuoteInProgress,
      isSourceAmountValid,
      isTargetAmountValid,
      quote,
      quoteStatusLabel,
      recipient,
      recipientLabel: labels.recipientLabel,
      reference,
      sender,
      senderLabel: labels.senderLabel,
      setAmount,
      setAmountType,
      setIsSourceAmountValid,
      setIsTargetAmountValid,
      setRecipient,
      setReference,
      setSender,
      setSourceAmount,
      setSourceAssetType,
      setSupportingDocumentFile,
      setTargetAmount,
      setTargetAssetType,
      setTransactionPurpose,
      sourceAmount,
      sourceAssetType,
      supportingDocumentFile,
      targetAmount,
      targetAssetType,
      transactionPurpose,
    }),
    [
      isQuoteInProgress,
      labels.recipientLabel,
      labels.senderLabel,
      quote,
      quoteStatusLabel,
      isQuoteFormDisabled,
      isSourceAmountValid,
      isTargetAmountValid,
      setIsSourceAmountValid,
      setIsTargetAmountValid,
      recipient,
      reference,
      setReference,
      sender,
      sourceAmount,
      sourceAssetType,
      supportingDocumentFile,
      targetAmount,
      targetAssetType,
      transactionPurpose,
    ],
  );

  return (
    <CreateTransactionContext.Provider value={contextValue}>
      {children}
    </CreateTransactionContext.Provider>
  );
};
