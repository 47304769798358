import { Link } from 'react-router-dom';
import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import React, { FC, useMemo } from 'react';

import { Organization } from 'store/api/api.types';
import { PRINT_ROUTES } from 'router/constants';
import { formatAmount, getUriId, getUriIdAndChainId } from 'utils/format';
import { useGetTransactionSummariesQuery } from 'store/api/platformApi';
import Button from 'components/core/Button/Button';
import Spinner from 'components/core/Spinner/Spinner';
import Table from 'components/core/Table/Table';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';
import usePaginationLinks from 'hooks/usePaginationLinks';

import { ModalTransaction } from '../send-money';

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor(row => row.createdAt, {
    cell: info => (
      <Text variant='bodyCopySmall'>{format(parseISO(info.getValue()), 'y-MM-dd')} </Text>
    ),
    header: 'Date',
    id: 'createdAt',
  }),
  columnHelper.accessor(row => row.id, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()} </Text>,
    header: 'Txn ID',
    id: 'txnId',
  }),
  columnHelper.accessor(row => row.description, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()} </Text>,
    header: 'Description',
    id: 'description',
  }),
  columnHelper.accessor(row => row.recipient, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()} </Text>,
    header: 'Recipient',
    id: 'recipient',
  }),
  columnHelper.accessor(row => row.total, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()} </Text>,
    header: 'Total',
    id: 'total',
  }),
  columnHelper.accessor(row => row.status, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()}</Text>,
    header: 'Status',
    id: 'status',
  }),
  columnHelper.accessor(row => row.button, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()}</Text>,
    header: '',
    id: 'modal',
  }),
];

const TransactionsTable: FC<{ organizationId: Organization['id'] }> = ({ organizationId }) => {
  const params = new URLSearchParams(window.location.search);
  const queryParamPageAfter = params.get('pageAfter');
  const queryParamPageBefore = params.get('pageBefore');
  const {
    data: transactiosSummaries,
    isLoading,
    isFetching,
  } = useGetTransactionSummariesQuery({
    organizationId,
    params: {
      page: {
        after: queryParamPageAfter || undefined,
        before: queryParamPageBefore || undefined,
      },
    },
  });
  const { handleOnNextPage, handleOnPreviousPage } = usePaginationLinks(
    transactiosSummaries?.links,
  );

  const transactions = useMemo<any[]>(() => {
    if (!transactiosSummaries) {
      return [];
    }

    const rows: any[] = [];

    transactiosSummaries.data.forEach(transaction => {
      const { attributes, id } = transaction;
      let description: any;
      let recipient = '';
      let total = formatAmount({
        amount: attributes.destination.amount,
        assetType: attributes.destination.assetType,
      });
      let button: any;

      let status = 'Pending';
      if (['posted', 'completed'].includes(attributes.status)) {
        status = 'Completed';
      } else if (['rejected_compliance', 'compliance_rejected'].includes(attributes.status)) {
        status = 'Rejected Compliance';
      } else if (['rejected', 'canceled', 'cancelled', 'blocked'].includes(attributes.status)) {
        status = 'Rejected';
      }

      if (attributes.transactionType === 'onramp') {
        description = `${getUriId(attributes.source.assetType)} > ${getUriIdAndChainId(attributes.destination.assetType)}`;
        recipient = `My ${getUriIdAndChainId(attributes.destination.assetType)} Balance`;
      } else if (attributes.transactionType === 'offramp') {
        description = `${getUriIdAndChainId(attributes.source.assetType)} > ${getUriId(attributes.destination.assetType)}`;
        recipient = attributes.destination.bank?.nameOnBankAccount;
        total = `-${total}`;
      } else if (attributes.transactionType === 'fx') {
        description = `${getUriId(attributes.source.assetType)} > ${getUriId(attributes.destination.assetType)}`;
        recipient = attributes.destination.bank?.nameOnBankAccount;
      } else if (attributes.transactionType === 'deposit') {
        description = `${getUriIdAndChainId(attributes.source.assetType)} Deposit`;
        recipient = `My ${getUriIdAndChainId(attributes.destination.assetType)} Balance`;
      } else if (attributes.transactionType === 'withdrawal') {
        description = `${getUriIdAndChainId(attributes.source.assetType)} Withdrawal`;
        recipient = getUriId(attributes.destination.id);
        total = `-${total}`;
      } else {
        description = `${getUriIdAndChainId(attributes.destination.assetType)} Interpool`;
        recipient = `My ${getUriIdAndChainId(attributes.destination.assetType)} Balance`;
      }

      if (status === 'Pending' && ['onramp', 'fx'].includes(attributes.transactionType)) {
        button = (
          <Button
            label='View Instructions'
            onClick={() =>
              modalService.openModal(
                { title: 'Fiat Deposit Instructions' },
                <ModalTransaction organizationId={organizationId} transactionId={id} />,
              )
            }
            size='small'
            variant='secondary'
          />
        );
      } else if (status === 'Completed' && ['offramp', 'fx'].includes(attributes.transactionType)) {
        button = (
          <Link
            style={{ textDecoration: 'none' }}
            target='_blank'
            to={`${PRINT_ROUTES.PAYMENT_RECEIPTS.absolute}?organizationId=${organizationId}&transactionId=${id}`}
          >
            <Button label='View Payment Receipt' size='small' variant='secondary' />
          </Link>
        );
      }

      const transactionRow = {
        button,
        createdAt: attributes.createdAt,
        description,
        id: getUriId(id),
        recipient,
        status,
        total,
        transactionId: id,
      };
      rows.push(transactionRow);
    });

    return rows;
  }, [transactiosSummaries, organizationId]);

  if (isLoading) {
    return <Spinner display='centered' />;
  }

  return (
    <div>
      {transactions?.length > 0 ? (
        <Table
          handleOnNextPage={handleOnNextPage}
          handleOnPreviousPage={handleOnPreviousPage}
          isLoading={isFetching}
          options={{
            columns,
            data: transactions,
            getCoreRowModel: getCoreRowModel(),
            manualPagination: true,
          }}
        />
      ) : (
        <Text align='center' marginTop={5} variant='bodyCopySmall'>
          No Transactions Created
        </Text>
      )}
    </div>
  );
};

export default TransactionsTable;
