import React, { FC } from 'react';

import { Organization } from 'store/api/api.types';
import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import modalService from 'services/modalService';

import TransactionDetail from '../../TransactionDetail/TransactionDetail';

const ModalDeposit: FC<{
  organizationId: Organization['id'];
  transactionId: string;
}> = ({ organizationId, transactionId }) => {
  return (
    <>
      <ModalContent>
        <TransactionDetail organizationId={organizationId} transactionId={transactionId} />
      </ModalContent>
      <ModalContent variant='footerButtonsWithoutTopBorder'>
        <Button
          label='Close'
          onClick={() => modalService.closeCurrentModal()}
          variant='secondary'
        />
      </ModalContent>
    </>
  );
};

export default ModalDeposit;
