import { useParams } from 'react-router-dom';
import React, { ReactElement } from 'react';

import ContentSection from 'components/core/ContentSection/ContentSection';
import LegacyTransactionsTable from 'components/dedicated/organization/LegacyTransactionsTable/LegacyTransactionsTable';
import PageHeader from 'components/core/PageHeader/PageHeader';

const LegacyTransactionsView = (): ReactElement => {
  const { organizationId } = useParams() as { organizationId: string };

  return (
    <ContentSection>
      <PageHeader title='Transactions' />
      <LegacyTransactionsTable organizationId={organizationId} />
    </ContentSection>
  );
};

export default LegacyTransactionsView;
