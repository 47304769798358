import React, { FC, useMemo } from 'react';
import cx from 'classnames';

import { PAGE_SIZE_OPTIONS } from 'components/core/PaginationBar/PaginationBar.constants';
import { chevronLeft, chevronRight } from 'components/core/Svg/icons';
import BackToTopButton from 'components/core/BackToTopButton/BackToTopButton';
import Button from 'components/core/Button/Button';
import SelectField from 'components/core/Form/SelectField/SelectField';
import Svg from 'components/core/Svg/Svg';

import { PaginationBarProps } from './PaginationBar.types';
import styles from './PaginationBar.module.scss';

const PaginationBar: FC<PaginationBarProps> = ({
  className,
  dataTestId,
  pageSize,
  onNextPage,
  onPrevPage,
  onLimitSelect,
  resultsCount,
}) => {
  const hasPagination = useMemo(() => onNextPage || onPrevPage, [onNextPage, onPrevPage]);

  return (
    <div className={cx(styles.root, className)} data-testid={dataTestId}>
      <BackToTopButton className={styles.backToTopButton} />
      <div className={styles.contentWrapper}>
        {resultsCount && (
          <div className={styles.resultsCount}>Displaying {resultsCount} results</div>
        )}
        {hasPagination && (
          <div className={styles.pagination}>
            <Button
              className={styles.paginationButton}
              Icon={<Svg img={chevronLeft} size={0.8} />}
              isDisabled={!onPrevPage}
              label='Back'
              onClick={onPrevPage}
              size='small'
              variant='secondary'
            />
            <Button
              className={styles.paginationButton}
              Icon={<Svg img={chevronRight} size={0.8} />}
              iconPosition='right'
              isDisabled={!onNextPage}
              label='Next'
              onClick={onNextPage}
              size='small'
              variant='secondary'
            />
          </div>
        )}
        {pageSize && (
          <div className={styles.resultsPerPage}>
            <div>Results per page</div>
            <SelectField
              className={styles.selectField}
              isDisabled={!onLimitSelect}
              isSearchable={false}
              onSelect={onLimitSelect}
              options={PAGE_SIZE_OPTIONS}
              size='small'
              value={pageSize}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaginationBar;
