import React, { FC } from 'react';
import cx from 'classnames';

import { TransactionType } from 'store/api/api.types';
import { arrowRight, crossmark } from 'components/core/Svg/icons';
import { getParsedTransactionItemAttributes } from 'components/dedicated/TransactionItem/TransactionItem.helpers';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import HexId from 'components/core/HexId/HexId';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';
import TransactionDescription from 'components/dedicated/TransactionDescription/TransactionDescription';
import TransactionStatusBadge from 'components/dedicated/TransactionStatusBadge/TransactionStatusBadge';
import TransactionTypeIcon from 'components/dedicated/TransactionTypeIcon/TransactionTypeIcon';
import modalService from 'services/modalService';

import { SlideoutTransactionDetailsHeaderProps } from './SlideoutTransactionDetailsHeader.types';
import styles from './SlideoutTransactionDetailsHeader.module.scss';

const SlideoutTransactionDetailsHeader: FC<SlideoutTransactionDetailsHeaderProps> = ({
  className,
  dataTestId,
  transactionSummary,
}) => {
  const { attributes } = transactionSummary;
  const {
    sender,
    sourceAmount,
    sourceAsset,
    recipient,
    status,
    targetAsset,
    targetAmount,
    transactionType,
  } = getParsedTransactionItemAttributes(attributes);

  return (
    <div className={cx(styles.root, className)} data-testid={dataTestId}>
      <Button
        className={styles.closeButton}
        Icon={<Svg img={crossmark} size={1.4} />}
        onClick={() => modalService.closeCurrentModal()}
        variant='icon'
      />
      <Box display='flex' marginBottom={6}>
        <TransactionTypeIcon marginRight={4} transactionType={transactionType} />
        <TransactionDescription
          marginRight={4}
          sourceAsset={sourceAsset}
          targetAsset={targetAsset}
          transactionType={transactionType}
        />
        <TransactionStatusBadge size='small' status={status} />
      </Box>
      <Box display='flex'>
        <div className={styles.column}>
          <Text marginBottom={2} variant='subCopySmall'>
            From
          </Text>
          <Text marginBottom={2} variant='sectionHeaderLarge'>
            {sourceAmount} {sourceAsset}
          </Text>
          <Text variant='subCopySmall'>
            {transactionType === TransactionType.Deposit ? (
              <HexId id={`From ${sender}`} minLeftChars={10} />
            ) : (
              sender
            )}
          </Text>
        </div>
        <Box marginLeft={12} marginRight={12}>
          <Svg className={styles.arrowIcon} img={arrowRight} size={1.6} />
        </Box>
        <div className={styles.column}>
          <Text marginBottom={2} variant='subCopySmall'>
            To
          </Text>
          <Text marginBottom={2} variant='sectionHeaderLarge'>
            {targetAmount} {targetAsset}
          </Text>
          <Text variant='subCopySmall'>
            {transactionType === TransactionType.Withdrawal ? <HexId id={recipient} /> : recipient}
          </Text>
        </div>
      </Box>
    </div>
  );
};

export default SlideoutTransactionDetailsHeader;
