import {
  Navigate,
  Outlet,
  generatePath,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import React, { ReactElement, useMemo } from 'react';

import { APP_ROUTES, ORGANIZATIONS_ROUTES, ORGANIZATION_ROUTES } from 'router/constants';
import { MobileMenuContextProvider } from 'context/MobileMenuContextProvider';
import { showError } from 'services/notificationService';
import { useGetOrganizationQuery, useGetOrganizationsQuery } from 'store/api/rootApi';
import ContentSection from 'components/core/ContentSection/ContentSection';
import LoaderView from 'components/views/LoaderView/LoaderView';
import MobileMenu from 'components/dedicated/MobileMenu/MobileMenu';
import OrganizationNavbar from 'components/dedicated/organization/OrganizationNavbar/OrganizationNavbar';
import Spinner from 'components/core/Spinner/Spinner';
import Topbar from 'components/dedicated/Topbar/Topbar';

import styles from './OrganizationLayout.module.scss';

const OrganizationLayout = (): ReactElement => {
  const { pathname } = useLocation();
  const { organizationId } = useParams() as { organizationId: string };
  const { data: organizations } = useGetOrganizationsQuery();
  const { data: organization, isFetching: isFetchingOrganization } = useGetOrganizationQuery({
    organizationId,
  });

  const canUsePlatform = useMemo(() => organization?.platform.canUsePlatform, [organization]);

  if (
    !isFetchingOrganization &&
    !organization &&
    !matchPath(APP_ROUTES.ORGANIZATIONS.absolute, pathname)
  ) {
    showError('Invalid organization ID.');
    // If organization id is invalid and there's only one organization,
    // redirect to create organization view to avoid infinite redirection loop.
    if (organizations?.length === 1 && organizations[0].id === organizationId) {
      return <Navigate replace to={ORGANIZATIONS_ROUTES.CREATE_ORGANIZATION.absolute} />;
    }
    return <Navigate replace to={APP_ROUTES.ORGANIZATIONS.absolute} />;
  }

  if (!organization || isFetchingOrganization) {
    return <LoaderView />;
  }

  const pendingVerificationPath = generatePath(ORGANIZATION_ROUTES.PENDING_VERIFICATION.absolute, {
    organizationId,
  });
  if (!canUsePlatform && !matchPath(pendingVerificationPath, pathname)) {
    return <Navigate replace to={pendingVerificationPath} />;
  }

  return (
    <MobileMenuContextProvider>
      <MobileMenu />
      <div className={styles.root}>
        <Topbar showMobileMenuButton />
        {isFetchingOrganization ? (
          <ContentSection>
            <Spinner display='centered' />
          </ContentSection>
        ) : (
          <>
            <OrganizationNavbar />
            <Outlet />
          </>
        )}
      </div>
    </MobileMenuContextProvider>
  );
};

export default OrganizationLayout;
