import React, { FC } from 'react';
import cx from 'classnames';

import Tab from 'components/core/Tab/Tab';

import { TabsProps } from './Tabs.types';
import styles from './Tabs.module.scss';

const Tabs: FC<TabsProps> = ({ className, dataTestId, tabs, variant }) => (
  <div
    className={cx(styles[`variant--${variant}`], styles.root, className)}
    data-testid={dataTestId}
  >
    {tabs.map((tab, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Tab key={`${index}-${tab.isActive}`} className={styles.tab} {...tab} variant={variant} />
    ))}
  </div>
);

export default Tabs;
