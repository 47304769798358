import React, { FC, useCallback } from 'react';
import cx from 'classnames';

import { TransactionType } from 'store/api/api.types';
import HexId from 'components/core/HexId/HexId';
import TransactionDescription from 'components/dedicated/TransactionDescription/TransactionDescription';
import TransactionTypeIcon from 'components/dedicated/TransactionTypeIcon/TransactionTypeIcon';

import { TransactionItemProps } from './TransactionItem.types';
import { getParsedTransactionItemAttributes } from './TransactionItem.helpers';
import styles from './TransactionItem.module.scss';

import TransactionStatusBadge from '../TransactionStatusBadge/TransactionStatusBadge';

const TransactionItem: FC<TransactionItemProps> = ({
  className,
  onClick,
  dataTestId,
  transactionSummary,
}) => {
  const { attributes } = transactionSummary;
  const {
    creationTime,
    sender,
    sourceAmount,
    sourceAsset,
    recipient,
    status,
    targetAsset,
    targetAmount,
    transactionType,
  } = getParsedTransactionItemAttributes(attributes);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(transactionSummary);
    }
  }, [onClick, transactionSummary]);

  return (
    <div className={cx(styles.root, className)} data-testid={dataTestId} onClick={handleOnClick}>
      <div className={styles.date}>
        <span className={styles.month}>{creationTime.month}</span>
        <span className={styles.day}>{creationTime.day}</span>
      </div>
      <TransactionTypeIcon className={styles.iconWrapper} transactionType={transactionType} />
      <div>
        <TransactionDescription
          sourceAsset={sourceAsset}
          targetAsset={targetAsset}
          transactionType={transactionType}
        />
        <div className={styles.senderAndAmount}>
          {transactionType === TransactionType.Deposit ? (
            <HexId id={`From ${sender}`} minLeftChars={10} />
          ) : (
            <span className={styles.sender}>{sender}</span>
          )}
          {sourceAmount && transactionType !== TransactionType.Withdrawal && (
            <span className={styles.amount}>
              &nbsp;|&nbsp;{sourceAmount} {sourceAsset}
            </span>
          )}
        </div>
      </div>
      <div className={styles.detailsRight}>
        <div className={styles.targetAmount}>
          {targetAmount} {targetAsset}
        </div>
        <div className={styles.recipient}>
          To {transactionType === TransactionType.Withdrawal ? <HexId id={recipient} /> : recipient}
        </div>
      </div>
      <div className={styles.status}>
        <TransactionStatusBadge status={status} />
      </div>
    </div>
  );
};

export default TransactionItem;
