import { AssetTypeKey } from 'store/types';
import { Chain } from 'store/api/api.types';

import { AssetType } from './assetTypes.types';
import { TimeZone } from './constants';

export const ASSET_TYPES: Record<AssetTypeKey, AssetType> = {
  ETH_USDC: {
    iconName: 'assetTypeUSDC',
    id: 'asset:eth:USDC',
    min: BigInt(10000000),
    name: 'USD Coin',
    networkIconName: 'assetChainETH',
    precision: 'Mwei',
    ticker: 'USDC',
    validationRegEx: /^(0x)[a-fA-F0-9]{40}$/,
    withdrawMin: BigInt(10000),
  },
  ETH_USDT: {
    iconName: 'assetTypeUSDT',
    id: 'asset:eth:USDT',
    min: BigInt(10000000),
    name: 'Tether',
    networkIconName: 'assetChainETH',
    precision: 'Mwei',
    quoteMax: BigInt(3000000000000),
    ticker: 'USDT',
    validationRegEx: /^(0x)[a-fA-F0-9]{40}$/,
    withdrawMin: BigInt(10000),
  },
  FIAT_ARS: {
    iconName: 'assetTypeARS',
    id: 'asset:fiat:ARS',
    min: BigInt(3500000000),
    name: 'Argentine Peso',
    precision: 'Mwei',
    ticker: 'ARS',
  },
  FIAT_BRL: {
    iconName: 'assetTypeBRL',
    id: 'asset:fiat:BRL',
    min: BigInt(50000000),
    name: 'Brazilian Real',
    precision: 'Mwei',
    ticker: 'BRL',
  },
  FIAT_CLP: {
    iconName: 'assetTypeCLP',
    id: 'asset:fiat:CLP',
    min: BigInt(8700000000),
    name: 'Chilean Peso',
    precision: 'Mwei',
    ticker: 'CLP',
  },
  FIAT_COP: {
    assetTypePairRules: [
      {
        assetTypes: ['asset:eth:USDT', 'asset:trx:USDT'],
        max: BigInt(4000000000000000),
        maxMessage:
          'Max quote limit is 4B COP. For higher quotes, contact our team for custom pricing.',
      },
    ],
    disabledMessage: `This trading pair is currently unavailable. It may be because it hasn't been enabled yet on your account or because we have depleted our daily COP liquidity. If you are interested in getting COP enabled, please contact our sales team. Otherwise, please try again tomorrow.`,
    iconName: 'assetTypeCOP',
    id: 'asset:fiat:COP',
    min: BigInt(40700000000),
    name: 'Colombian Peso',
    operatingHours: {
      message:
        'We only process COP transactions between 9:30 AM and 5:00 PM ET. Please come back during these hours to complete your transaction.',
      start: { hour: 9, minute: 30 },
      stop: { hour: 17, minute: 0 },
      timezone: TimeZone.ET,
    },
    precision: 'Mwei',
    ticker: 'COP',
  },
  FIAT_MXN: {
    assetTypePairRules: [
      {
        assetTypes: ['asset:eth:USDT', 'asset:trx:USDT'],
        max: BigInt(10000000000000),
        maxMessage:
          'Max quote limit is 10M MXN. For higher quotes, contact our team for custom pricing.',
      },
    ],
    iconName: 'assetTypeMXN',
    id: 'asset:fiat:MXN',
    min: BigInt(176000000),
    name: 'Mexican Peso',
    precision: 'Mwei',
    ticker: 'MXN',
  },
  FIAT_PEN: {
    iconName: 'assetTypePEN',
    id: 'asset:fiat:PEN',
    min: BigInt(37000000),
    name: 'Peruvian Sol',
    precision: 'Mwei',
    ticker: 'PEN',
  },
  FIAT_USD: {
    assetTypePairRules: [
      {
        assetTypes: ['asset:eth:USDT', 'asset:trx:USDT'],
        max: BigInt(3000000000000),
        maxMessage:
          'Max quote limit is $3M USD. For higher quotes, contact our team for custom pricing.',
      },
    ],
    holidays: [
      {
        dateEnd: new Date('2023-12-26T00:00:00-05:00'),
        dateStart: new Date('2023-12-20T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Dec 25 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-01-02T00:00:00-05:00'),
        dateStart: new Date('2023-12-27T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Jan 1 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-01-16T00:00:00-05:00'),
        dateStart: new Date('2024-01-10T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Jan 15 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-02-20T00:00:00-05:00'),
        dateStart: new Date('2024-02-14T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Feb 19 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-05-28T00:00:00-05:00'),
        dateStart: new Date('2024-05-22T00:00:00-05:00'),
        message:
          'U.S. banks are closed on May 27 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-06-20T00:00:00-05:00'),
        dateStart: new Date('2024-06-14T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Jun 19 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-07-05T00:00:00-05:00'),
        dateStart: new Date('2024-07-01T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Jul 4 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-09-03T00:00:00-05:00'),
        dateStart: new Date('2024-08-28T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Sep 2 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-10-15T00:00:00-05:00'),
        dateStart: new Date('2024-10-09T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Oct 14 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-11-12T00:00:00-05:00'),
        dateStart: new Date('2024-11-06T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Nov 11 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-11-29T00:00:00-05:00'),
        dateStart: new Date('2024-11-25T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Nov 28 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2024-12-26T00:00:00-05:00'),
        dateStart: new Date('2024-12-20T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Dec 25 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-01-02T00:00:00-05:00'),
        dateStart: new Date('2024-12-27T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Jan 1 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-01-21T00:00:00-05:00'),
        dateStart: new Date('2025-01-15T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Jan 20 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-02-18T00:00:00-05:00'),
        dateStart: new Date('2025-02-12T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Feb 17 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-05-27T00:00:00-05:00'),
        dateStart: new Date('2025-05-21T00:00:00-05:00'),
        message:
          'U.S. banks are closed on May 26 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-06-20T00:00:00-05:00'),
        dateStart: new Date('2025-06-14T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Jun 19 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-07-05T00:00:00-05:00'),
        dateStart: new Date('2025-07-01T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Jul 4 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-09-02T00:00:00-05:00'),
        dateStart: new Date('2025-08-27T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Sep 1 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-10-14T00:00:00-05:00'),
        dateStart: new Date('2025-10-08T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Oct 13 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-11-12T00:00:00-05:00'),
        dateStart: new Date('2025-11-06T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Nov 11 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-11-28T00:00:00-05:00'),
        dateStart: new Date('2025-11-24T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Nov 27 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2025-12-26T00:00:00-05:00'),
        dateStart: new Date('2025-12-20T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Dec 25 - no USD pay-ins or payouts will be processed on that day.',
      },
      {
        dateEnd: new Date('2026-01-02T00:00:00-05:00'),
        dateStart: new Date('2025-12-27T00:00:00-05:00'),
        message:
          'U.S. banks are closed on Jan 1 - no USD pay-ins or payouts will be processed on that day.',
      },
    ],
    iconName: 'assetTypeUSD',
    id: 'asset:fiat:USD',
    min: BigInt(10000000),
    name: 'US Dollar',
    precision: 'Mwei',
    sourceCutOffMessage:
      'Funds received & settled before 4pm ET are processed same banking day. If settled after 4pm ET, they are processed the next banking day.',
    targetCutOffMessage:
      "Domestic U.S. payouts initiated before 4pm ET are processed same day; recipient's receipt of the funds depends on interbank transfer cycles and their own bank's rules.",
    ticker: 'USD',
  },
  TRX_USDT: {
    iconName: 'assetTypeUSDT',
    id: 'asset:trx:USDT',
    min: BigInt(10000000),
    name: 'Tether',
    networkIconName: 'assetChainTRX',
    precision: 'Mwei',
    quoteMax: BigInt(3000000000000),
    ticker: 'USDT',
    validationRegEx: /^T[0-9a-zA-Z]{33}$/,
    withdrawMin: BigInt(10000),
  },
};

export const SEND_MONEY_CURRENCY_TYPES: AssetType[] = [
  ASSET_TYPES.FIAT_ARS,
  ASSET_TYPES.FIAT_BRL,
  ASSET_TYPES.FIAT_CLP,
  // ASSET_TYPES.FIAT_COP,
  ASSET_TYPES.FIAT_MXN,
  ASSET_TYPES.FIAT_PEN,
  ASSET_TYPES.FIAT_USD,
  ASSET_TYPES.ETH_USDC,
  ASSET_TYPES.ETH_USDT,
  ASSET_TYPES.TRX_USDT,
];

export const CHAINS: Chain[] = [
  {
    explorerUrl: 'https://etherscan.io/tx/',
    fungibleToken: 'ERC-20',
    id: 'ETH',
    name: 'Ethereum',
  },
  {
    explorerUrl: 'https://tronscan.org/#/transaction/',
    fungibleToken: 'TRC-20',
    id: 'TRX',
    name: 'TRON',
  },
];
